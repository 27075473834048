import React, {useState} from 'react';

import {Typography,  ListItem, ListItemText, ListItemIcon, CircularProgress, useTheme, makeStyles, IconButton, Link, Box} from '@material-ui/core';
import {SQLtoUTCDate} from '../global/dateTimeHelpers';

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import MovieIcon from '@material-ui/icons/Movie';
import CloseIcon from '@material-ui/icons/Close';
import API from '../global/api';
import ChargepointPhotoDelete from './chargepointPhotoDelete';



const useStyles = makeStyles(theme => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    initials: {
        marginRight: theme.spacing(1)
    },
    delete: {
        width: 16, 
        height: 16, 
        marginLeft: 15,
        color: theme.palette.text.disabled,
        "&:hover" : {
            color: theme.palette.text.default
        }
    },
}));

const ChargepointPhoto = props => {
    const theme = useTheme();
    const styles = useStyles(theme);

    const {attachment} = props;

    const [downloading, setDownloading] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    

    const handleDownload = (e) => {
        
        e.preventDefault()
        e.stopPropagation()

        setDownloading(attachment.id_chargepoint_attachment);

        API.fileDownload(`/chargepoint/${attachment.id_chargepoint}/attach/${attachment.id_chargepoint_attachment}`, attachment.attachment_name).then(
            res => {
                setDownloading(null);
                console.log(res);
            },
            err => {
                setDownloading(null);
                console.log(err);
            }
        )
    }


    const fileTypeIcon = mimeType => {
        switch(mimeType.toUpperCase()){
            case 'IMAGE/JPEG':
            case 'IMAGE/PNG':
            case 'IMAGE/GIF':
                return <InsertPhotoIcon />
            case 'AUDIO/MPEG':
                return <AudiotrackIcon />
            case 'VIDEO/MPEG':
                return <MovieIcon />
            default:
                return <InsertDriveFileIcon />
        }
    }

    const handleDeleteSuccess = () => {
        setConfirmDelete(false);

        if(typeof props.onDelete === 'function')
            props.onDelete();
    }

    return (
        <React.Fragment>
            <ListItem>
                <ListItemIcon>
                    {downloading !== attachment.id_chargepoint_attachment && <IconButton onClick={(e) => handleDownload(e, attachment)}>{fileTypeIcon(attachment.attachment_filetype)}</IconButton>}
                    {downloading === attachment.id_chargepoint_attachment && <CircularProgress size={24} />}
                </ListItemIcon>
                <ListItemText
                    primary={<Link href="#" color="primary" onClick={(e) => handleDownload(e, attachment)}>{attachment.attachment_name} ({attachment.attachment_size})</Link>}
                    secondary={<Typography color="textSecondary" variant="caption">{SQLtoUTCDate(attachment.created, true)}</Typography>}
                />
                <Box>
                    <IconButton className={styles.delete} onClick={() => setConfirmDelete(true)}><CloseIcon fontSize="small" className={styles.deleteIcon} /></IconButton>
                </Box>
            </ListItem>

            <ChargepointPhotoDelete show={confirmDelete} attachment={attachment} onClose={() => setConfirmDelete(false)} onSuccess={handleDeleteSuccess} />
        </React.Fragment>
    )
}
export default ChargepointPhoto;