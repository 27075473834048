import { Box, Typography } from '@material-ui/core';
import React from 'react';
import Images from '../assets/imgs';
import Arrow from './arrow';

const NoChargePoints = props => {

  const label = props.label || null;
  const subLabel = props.subLabel || null;
  const point = props.point || null;

  return (
    <React.Fragment>

      <Box style={{display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center', justifyContent: 'center'}}>

        {(point !== null && point > 89 && point < 271) && <Arrow size={60} point={point} />}
        <Box style={{height: 120, width: 'auto'}}>
          <img src={Images.zura} alt="No charge points" style={{height: '100%', width: 'auto'}} />
        </Box>
        {label && <Typography variant="h6" color="textSecondary">{label}</Typography>}
        {subLabel && <Typography variant="caption" color="textSecondary">{subLabel}</Typography>}
        {(point !== null && (point < 90 || point > 270)) && <Arrow size={80} point={point} />}

      </Box>

    </React.Fragment>
  )
}

export default NoChargePoints