import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, {useState} from 'react';
import ProgressButton from '../components/progressButton';
import API from '../global/api';
import { isValidForm, makeValidation } from '../global/formHelper';


const TeamMemberEdit = props => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const {member} = props;
  const [form, setForm] = useState({role: props.member.role});

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'role',
      display_name: 'Role',
      required: true
    }
  ]))

  const handleInputChange = (e) => {
    let newForm = JSON.parse(JSON.stringify(form));
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = async () => {

    try {

      const isValid = isValidForm(form, validation);
      setValidation(isValid.validation);
      if(isValid.isValid) {
        setError(null);
        setLoading(true);
        const updateMember = await API.req(`/team/${member.id_team}/member/${member.id_user}`, 'PUT', form);
        setLoading(false);
        if(updateMember.success){
          if(typeof props.onSuccess === 'function')
            props.onSuccess();
        } else{
          setError(updateMember.msg)
        }
      }
      
    } catch (error) {
      setLoading(false);
      setError(typeof error.msg !== 'undefined' ? error.msg : JSON.stringify(error))
    }

  }

  const handleClose = () => {
    setError(null);
    if(typeof props.onClose === 'function')
      props.onClose();
  }


  return (
    <React.Fragment>

      <Dialog open={props.show} fullWidth maxWidth="xs" onClose={handleClose}>

        <DialogTitle>Update Member</DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}

          <FormControl fullWidth style={{marginBottom: 10}} variant="filled">
            <InputLabel shrink htmlFor="role-placeholder">
              Role
            </InputLabel>
            <Select
              value={form.role}
              onChange={handleInputChange}
              inputProps={{
                name: 'role',
                id: 'role-placeholder',
              }}
            >
              <MenuItem value="MEMBER">MEMBER</MenuItem>
              <MenuItem value="ADMIN">ADMIN</MenuItem>
            </Select>
          </FormControl>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ProgressButton variant="contained" onClick={handleSubmit} color="primary" waiting={loading}>UPDATE</ProgressButton>
        </DialogActions>

      </Dialog>

    </React.Fragment>
  )

}

export default TeamMemberEdit

