import React, {useEffect, useState} from 'react';
import io from 'socket.io-client';
import { makeStyles, useTheme, Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useSnackbar  } from 'notistack';

import { connect } from 'react-redux';
import { logout, userValidate } from '../actions/userActions';
import { projectsLoad, projectAdd, projectUpdate, projectRemove } from '../actions/projectActions';
import { teamsLoad, teamAdd, teamUpdate, teamRemove } from '../actions/teamActions';
import { chargepointsAdd, chargepointUpdate, chargepointRemove, chargepointsClear, chargepointSelect, chargepointSelectOne, chargepointUnselect, chargepointsClearSelected } from '../actions/chargePointsActions';
import GlobalContext from './globalContext';

import SideSlidePanel from '../components/sideSlidePanel';

import MainToolbar from '../content/mainToolbar';
import ChangePassword from '../content/changePassword';

import MainNavigation from '../content/mainNavigation';
import PrivacyNotice from '../content/privacyNotice';


//
//  Used for the silent refresh of the token
//
var silent_refresh;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '2%',
    paddingTop: '1%',
    flex: 1
  },
  inner: {
    flex: 1,
    width: '96%',
    display: 'flex',
  }
}))


const Main = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const version = process.env.REACT_APP_VERSION;

  const [showMenu, setShowMenu] = useState(false);

  const globalPanels = ['change_password'];

  const getDefaultGlobalPanelProps = () => {
    let panelProps = {};
    globalPanels.forEach(p => {
      panelProps[p] = {
        show: false,
        data: null
      };
    })
    return panelProps;
  }

  const [globalPanelProps, setGlobalPanelProps] = useState(getDefaultGlobalPanelProps());

  const handleToggleMenu = () => {
    setShowMenu(!showMenu);
  }

  const toggleGlobalPanel = (panel, show = false, data = null) => {
    let panels = {...globalPanelProps};
    Object.keys(panels).forEach(p => {
      panels[p].show = false;
      panels[p].data = null;
    })
    panels[panel].show = show;
    panels[panel].data = data;
    setGlobalPanelProps(panels);
  }




  useEffect(() => {
    if(typeof silent_refresh != 'undefined')
      clearTimeout(silent_refresh);

    if(props.user.status === 'in')
      silent_refresh = setTimeout(props.userValidate, Math.floor((props.user.profile.expires*.9)*1000));
  }, [props.user])

  useEffect(() =>{
    if(props.user.status === 'in')
      props.projectsLoad();
  }, [props.user.profile])

  
  /**
   * 
   * @param {Object} snack The properties of the snack
   * @param {String} snack.messsage e.g. 'Customer saved successfully'
   * @param {String} snack.id The data id to be added to the end of a predfined route by type e.g. B012
   * @param {String} snack.type 1 = /tickets/monitor/:id
   * @param {*} variant info | success | error | warning
   * 
   */
  const _showSnack = (snack, variant = null) => {
    var action = null;

    if(snack.id)
      action = key => (
        <React.Fragment>
          <Button onClick={() => _clickSnack(snack)}>Open</Button>
        </React.Fragment>
      )
    else
        action = key => (
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => { closeSnackbar(key) }}>
                <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        )

    enqueueSnackbar(snack.message, {variant: variant, action});
    
  };

  const _clickSnack = snack => {
    let url = null;

    switch(parseInt(snack.type)){
        case 1:
            //url = `/tickets/monitor/${snack.id}`;
            break;
    }

    if(url != null)
      props.history.push(url);
  }




  return (
    <GlobalContext.Provider value={{
      ...props,
      toggleGlobalPanel: toggleGlobalPanel,
      showSnack: _showSnack,
      version: version
    }}>
      

      {/* Global content which can appear from anywhere anytime */}
      <SideSlidePanel title='Change Password' show={globalPanelProps.change_password.show} handleClose={() => toggleGlobalPanel('change_password', false)} ><ChangePassword /></SideSlidePanel>

      <div style={{display: 'flex', flexDirection: 'row', minHeight: '100%', minWidth: '100%'}}>
        <PrivacyNotice/>
        {/* Structural components making up the application's frame */}
        <MainNavigation toggleDrawer={handleToggleMenu} open={showMenu} />
        <div style={{dispay: 'flex', flexDirection: 'column', flexGrow: 1, backgroundColor: theme.palette.grey[100], overflowY: 'auto'}}>
          <MainToolbar toggleMenu={handleToggleMenu}/>

          {/* Routing compnents */}
          <div className={classes.container}>
            <div className={classes.inner}>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </GlobalContext.Provider>
  );
}


const mapStateToProps = (state) => {
  return {
    user: state.user,
    project: state.project,
    chargepoints: state.chargepoints,
    teams: state.teams
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
    userValidate: () => {
      dispatch(userValidate());
    },
    projectsLoad: () => {
      dispatch(projectsLoad());
    },
    projectAdd: (payload) => {
      dispatch(projectAdd(payload));
    },
    projectUpdate: (payload) => {
      dispatch(projectUpdate(payload));
    },
    projectRemove: (payload) => {
      dispatch(projectRemove(payload));
    },
    chargepointsAdd: (payload) => {
      dispatch(chargepointsAdd(payload));
    },
    chargepointUpdate: (payload) => {
      dispatch(chargepointUpdate(payload));
    },
    chargepointRemove: (payload) => {
      dispatch(chargepointRemove(payload));
    },
    chargepointsClear: () => {
      dispatch(chargepointsClear());
    },
    chargepointSelect: (payload) => {
      dispatch(chargepointSelect(payload));
    },
    chargepointSelectOne: (payload) => {
      dispatch(chargepointSelectOne(payload));
    },
    chargepointUnselect: (payload) => {
      dispatch(chargepointUnselect(payload));
    },
    chargepointsClearSelected: () => {
      dispatch(chargepointsClearSelected());
    },
    teamsLoad: () => {
      dispatch(teamsLoad());
    },
    teamAdd: (payload) => {
      dispatch(teamAdd(payload));
    },
    teamUpdate: (payload) => {
      dispatch(teamUpdate(payload));
    },
    teamRemove: (payload) => {
      dispatch(teamRemove(payload));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);