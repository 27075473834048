import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, useTheme, Button, List, ListItem, ListItemText, Typography, CircularProgress, Box, IconButton, Paper, Menu, MenuItem} from '@material-ui/core';
import PageToolbar from '../components/pageToolbar';
import globalContext from '../context/globalContext';
import SideSlidePanel from '../components/sideSlidePanel';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useHistory } from 'react-router-dom';
import TeamAdd from '../content/teamAdd';
import TeamMore from '../content/teamMore';
import TeamDefault from '../content/teamDefault';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import TeamLeave from '../content/teamLeave';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  busyBox: {
    height: 200, 
    width: '100%', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center'
  }
}))


const TeamListItem = props => {

  const context = useContext(globalContext);
  const {team, idx, handleClick} = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showDefault, setShowDefault] = useState(false);
  const [showLeave, setShowLeave] = useState(false);


  const handleDefaultChanged = () => {
    setShowDefault(false);
    // Re-validating the user will re-fetch the preferences
    context.userValidate();
  }

  const handleLeaveChanged = () => {
    setShowLeave(false);
    context.teamsLoad();
  }

  return (
      <React.Fragment>
        <ListItem button={team.role === 'ADMIN'} style={{borderTop: idx === 0 ? 'none' : '1px solid #c4c4c4'}} onClick={() => handleClick(team)}>
          <ListItemText primary={`${team.team_name} ${context.user.profile.user.preferences.default_id_team === team.id_team ? '(Default)' : ''}`} secondary={`${team.members} Member${team.members > 1 ? 's' : ''}`} />
          <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center'}}>
            <Typography variant="body1">{team.role}</Typography>
            <IconButton onClick={e => {e.stopPropagation(); setAnchorEl(e.currentTarget);}}><MoreVertIcon /></IconButton>
          </Box>
        </ListItem>

        <Menu
          id="default-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => setShowDefault(true)}>Make default</MenuItem>
          <MenuItem onClick={() => setShowLeave(true)}>Leave team</MenuItem>
        </Menu>

        <TeamDefault onSuccess={handleDefaultChanged} team={team} show={showDefault} onClose={() => setShowDefault(false)} />
        <TeamLeave onSuccess={handleLeaveChanged} team={team} show={showLeave} onClose={() => setShowLeave(false)} />
      </React.Fragment>
  )
}


const Teams = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);
  const history = useHistory();

  const [showAdd, setShowAdd] = useState(false);
  const [showMore, setShowMore] = useState(null);

  useEffect(() =>{
    context.teamsLoad();
  }, [])

  const handleClick = t => {
    if(t.role === 'ADMIN')
      setShowMore(t);
  }


  return (

    <div className={classes.container}>

      <PageToolbar title="Teams">
        <IconButton onClick={context.teamsLoad} color="primary">{context.teams.status === 'LOADING' ? <CircularProgress size={18} /> : <RefreshIcon />}</IconButton>
        <Button variant="contained" color="primary" onClick={() => setShowAdd(true)}>NEW</Button>
      </PageToolbar>

      <Paper style={{padding: 20}}>

        {(context.teams.status !== 'LOADING' && context.teams.data.length > 0) &&
          <List>
            {context.teams.data.map((t, idx) => (
              <TeamListItem  key={t.id_team} team={t} idx={idx} handleClick={handleClick}/>
            ))}
          </List>
        }

        {context.teams.status === 'LOADING' && <Box className={classes.busyBox}><CircularProgress size={36} /></Box>}

        {(context.teams.status !== 'LOADING' && context.teams.data.length === 0) && 
          <Box className={classes.busyBox}>
              <Typography variant="h6" color="textSecondary">You have no teams, click NEW above to create a team</Typography>
          </Box>
        } 

      </Paper>
      
      <SideSlidePanel show={showAdd} handleClose={() => setShowAdd(false)} title="New Team" side="RIGHT"><TeamAdd onSuccess={() => setShowAdd(false)} /></SideSlidePanel>
      <SideSlidePanel show={showMore !== null} handleClose={() => setShowMore(null)} title="Team" side="RIGHT"><TeamMore onClose={() => setShowMore(null)} team={showMore}/></SideSlidePanel>

    </div>
  );
}

export default Teams;
