import logo from '../../assets/imgs/logo.svg';
import logo_white from '../../assets/imgs/logo-white.svg';
import logo_inline from '../../assets/imgs/logo-inline.svg';
import logo_inline_white from '../../assets/imgs/logo-inline-white.svg';
import logo_icon from '../../assets/imgs/logo-icon.svg';
import logo_icon_white from '../../assets/imgs/logo-icon-white.svg';
import arrow from '../../assets/imgs/arrow.svg';
import zura from '../../assets/imgs/zura.svg';
import icon_installer from '../../assets/imgs/icon-installer.svg';
import icon_installer_white from '../../assets/imgs/icon-installer-white.svg';
import icon_commercial from '../../assets/imgs/icon-commercial.svg';
import icon_commercial_white from '../../assets/imgs/icon-commercial-white.svg';
import icon_home from '../../assets/imgs/icon-home.svg';
import icon_home_white from '../../assets/imgs/icon-home-white.svg';
import icon_owner from '../../assets/imgs/icon-owner.svg';
import icon_owner_white from '../../assets/imgs/icon-owner-white.svg';
import icon_dashboard from '../../assets/imgs/icon-dashboard.svg';
import icon_dashboard_white from '../../assets/imgs/icon-dashboard-white.svg';
import icon_location from '../../assets/imgs/icon-location.svg';

import cp_zura from './cp_zura.svg';
import cp_autocharge from './cp_autocharge.svg';
import cp_basiccharge from './cp_basiccharge.svg';
import cp_default from './cp_default.svg';
import cp_quantum from './cp_quantum.svg';
import cp_securicharge from './cp_securicharge.svg';
import cp_wallpod from './cp_wallpod.svg';

const images = {
    logo,
    logo_white,
    logo_inline,
    logo_inline_white,
    logo_icon,
    logo_icon_white,
    zura,
    arrow,
    icon_installer,
    icon_installer_white,
    icon_commercial,
    icon_commercial_white,
    icon_home,
    icon_home_white,
    icon_owner,
    icon_owner_white,
    icon_dashboard,
    icon_dashboard_white,
    icon_location,
    cp_zura,
    cp_autocharge,
    cp_basiccharge,
    cp_default,
    cp_quantum,
    cp_securicharge,
    cp_wallpod
}

export default images