import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme, Typography, CircularProgress, Box, CardActionArea, Card, Button} from '@material-ui/core';
import API from '../global/api';
import SideSlidePanel from '../components/sideSlidePanel';
import ProjectSiteMore from './projectSiteMore';
import NoSites from '../components/noSites';
import ProjectSiteAdd from './projectSiteAdd';
import Images from '../assets/imgs';
import ProjectSiteEdit from './projectSiteEdit';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  busyBox: {
    height: 300, 
    width: '100%', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center'
  }
}))


const ProjectSites = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const {project_code, id_project} = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [sites, setSites] = useState(null);
  const [selectSite, setSelectSite] = useState(false);
  const [editSite, setEditSite] = useState(false);
  const [addSite, setAddSite] = useState(false);



  const loadSites = async () => {

    try {

        setError(null);
        setLoading(true);

        const getSites = await API.req(`/project/sites/${project_code}`, 'GET');

        if(!getSites.success)
            throw getSites;

        setSites(getSites.result);

        setLoading(false);

        
    } catch (error) {
        setError('ERR_LOADING_SITES');
    }

  }
  
  useEffect(() =>{
    loadSites()
  }, [])


  const handleEdit = () => {
    setEditSite(selectSite);
    setSelectSite(false);
  }

  const handleEditClose = () => {
    setEditSite(false);
    setSelectSite(editSite);
  }

  const handleAddSuccess = () => {
    setAddSite(false);
    loadSites();
  }

  const handleEditSuccess = () => {
    setEditSite(false);
    loadSites();
  }

  const handleDeleteSuccess = () => {
    setSelectSite(false);
    loadSites();
  }


  return (

    <React.Fragment>

      <Box style={{paddingTop: 30}}>
        
        <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Typography variant="h6">Sites</Typography>
          <Button size="small" color="primary" variant="contained" onClick={() => setAddSite(true)}>NEW SITE</Button>
        </Box>

        {loading && <Box className={classes.busyBox}><CircularProgress size={36} /></Box>}
        {(!loading && sites !== null && sites.length === 0) && 
          <Box className={classes.busyBox}>
              <NoSites label="You have no sites" point={null} subLabel="Add a new site above and start assigning your charge points" />
          </Box>
        } 
        {error && <Alert severity="error">{error}</Alert> } 

        {(!loading && sites !== null && sites.length > 0) && 
          <Box className='flex-row-start pt' style={{flexWrap: 'wrap'}}>
            {sites.map((s, idx) => (
              <Card key={idx} style={{minWidth: 300}}>
                <CardActionArea onClick={() => setSelectSite(s)}>
                  <Box style={{display: 'flex', gap: 20, padding: 30}}>
                    <img src={Images.icon_location} alt={s.site_name} style={{height: 80, width: 'auto'}} />
                    <Box style={{display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="subtitle2" color="primary">{s.site_name}</Typography>
                        {s.chargepoints && <Typography variant="subtitle2">{`${s.chargepoints} Chargepoint${s.chargepoints > 1 ? 's' : ''}`}</Typography>}
                        {!s.chargepoints && <Typography variant="subtitle2">No Chargepoints</Typography>}
                        <Typography variant="body2" color="textSecondary">{`${s.town}, ${s.postal_code}`}</Typography>
                    </Box>
                  </Box>
                </CardActionArea>
              </Card>
            ))}
          </Box>
        } 
      </Box>

      <SideSlidePanel show={selectSite} handleClose={() => setSelectSite(false)} title="Site" side="RIGHT"><ProjectSiteMore site={selectSite} handleEdit={handleEdit} onDelete={handleDeleteSuccess} /></SideSlidePanel>
      <SideSlidePanel show={addSite} handleClose={() => setAddSite(false)} title="Add Site" side="RIGHT"><ProjectSiteAdd id_project={id_project} onSuccess={handleAddSuccess} /></SideSlidePanel>
      <SideSlidePanel show={editSite} handleClose={handleEditClose} title="Edit Site" side="RIGHT"><ProjectSiteEdit site={editSite} onSuccess={handleEditSuccess} /></SideSlidePanel>

    </React.Fragment>

  );
}

export default ProjectSites;
