import React, { useContext, useEffect, useState } from 'react';
import InputField from '../components/inputField';
import { isValidForm, makeValidation, validateForm } from '../global/formHelper';
import { Box } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import API from '../global/api';
import globalContext from '../context/globalContext';
import ProgressButton from '../components/progressButton';
import countries from 'i18n-iso-countries';
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const ProjectSiteEdit = props => {

  const context = useContext(globalContext);

  const isoCountries = countries.getAlpha2Codes();
  var availableCountries = [];

  const [form, setForm] = useState(props.site);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'site_name',
      display_name: 'Site Name',
      required: true
    },
    {
      name: 'address_line_1',
      display_name: 'Address Line 1',
      required: true
    },
    {
      name: 'address_line_2',
      display_name: 'Address Line 2',
      required: false
    },
    {
      name: 'address_line_3',
      display_name: 'Address Line 3',
      required: false
    },
    {
      name: 'town',
      display_name: 'Town',
      required: true
    },
    {
      name: 'county',
      display_name: 'County',
      required: false
    },
    {
      name: 'postal_code',
      display_name: 'Postal Code',
      required: false
    },
    {
      name: 'country',
      display_name: 'Country',
      required: true
    },
  ]))

  const handleInputChange = e => {
    const newForm = JSON.parse(JSON.stringify(form))
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = async () => {

    try {

      const isValid = isValidForm(form, validation);
      setValidation(isValid.validation);
      if(isValid.isValid) {

          setLoading(true);
          const updateProjectSite = await API.req(`/project/${form.id_project}/site/${form.id_project_site}`, 'PUT', form);
          if(updateProjectSite.success){
            context.projectAdd(updateProjectSite.result);
            setLoading(false);
            if(typeof props.onSuccess === 'function')
              props.onSuccess();
          } else {
            throw updateProjectSite;
          }

      }
      
    } catch (error) {
      setLoading(false);
      setError(typeof error.msg !== 'undefined' ? error.msg : JSON.stringify(error))
    }

  }

  useEffect(() => {

    setValidation(validateForm(form, validation))

  }, [form])



  const grabCountries = () => {
    let newCountries = [];
    Object.keys(isoCountries).map(c => 
      newCountries.push({
        key: c,
        value: countries.getName(c, "en")
      })
    );
    availableCountries = newCountries;

  }
  grabCountries();




  return (
    <Box style={{paddingTop: 30}}>
      {error && <Alert severity='error'>{error}</Alert>}
      <InputField hoverLabel={true} type="text" name="site_name" validation={validation.site_name} value={form.site_name} handleChange={handleInputChange} />
      <InputField hoverLabel={true} type="text" name="address_line_1" validation={validation.address_line_1} value={form.address_line_1} handleChange={handleInputChange} />
      <InputField hoverLabel={true} type="text" name="address_line_2" validation={validation.address_line_2} value={form.address_line_2} handleChange={handleInputChange} />
      <InputField hoverLabel={true} type="text" name="address_line_3" validation={validation.address_line_3} value={form.address_line_3} handleChange={handleInputChange} />
      
      <Box style={{width: '80%'}}>
        <InputField hoverLabel={true} type="text" name="town" validation={validation.town} value={form.town} handleChange={handleInputChange} />
        <InputField hoverLabel={true} type="text" name="county" validation={validation.county} value={form.county} handleChange={handleInputChange} />
      </Box>

      <Box style={{width: '50%'}}>
        <InputField hoverLabel={true} type="text" name="postal_code" validation={validation.postal_code} value={form.postal_code} handleChange={handleInputChange} />
      </Box>
      
      <Box style={{width: '80%'}}>
        <InputField hoverLabel={true} name="country" validation={validation.country} value={form.country} handleChange={handleInputChange} type="select" items={availableCountries} />
      </Box>
      <Box style={{display: 'flex', justifyContent: 'flex-end'}}>
        <ProgressButton variant="contained" onClick={handleSubmit} color="primary" size="large" waiting={loading}>Save</ProgressButton>
      </Box>
    </Box>
  )

}

export default ProjectSiteEdit