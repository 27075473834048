import { Dialog, DialogContent, Button, Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import GavelIcon from "@material-ui/icons/Gavel";


const InstallerEndUserLicenceAgreement = props => {

    const [hasRefused, setHasRefused] = useState(false);

    const handleAgree = () => {
        if(typeof props.onAccept === 'function')
            props.onAccept();
    }

    const handleDisagree = () => {
        setHasRefused(true);
        if(typeof props.onRefuse === 'function')
            props.onRefuse();
    }

    return (
        <React.Fragment>

        <Dialog open={props.show} fullWidth maxWidth="md">

            <DialogContent>
                <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '10px', paddingTop: 15}}>
                    <GavelIcon color="primary" style={{fontSize: 58}}/>
                    <Typography variant="h5">Please read and accept the terms.</Typography>
                    <Typography variant="subtitle2" style={{marginBottom: 15}}>This end user licence agreement contains important information that you must be aware of. </Typography>
                </Box>
                
                <Box className='terms-block'>

                    <p class="font_8">We, Rolec Services Limited, license you to use:</p>
                    <p class="font_8"></p>
                    <ul class="font_9">
                    <li><p class="font_9">The EVConnect mobile application software and the EVConnect+ Web Portal (described together in these terms as <strong>App</strong>) and any updates or supplements to them;</p></li>
                    <li><p class="font_9">The related online documentation (<strong>Documentation</strong>);</p></li>
                    <li><p class="font_9">The service you connect to via the App and the content we provide to you through it (<strong>Service</strong>);</p></li>
                    <li><p class="font_9">In each case, as permitted in these terms.</p></li>
                    </ul>
                    <p class="font_8"></p>
                    <p class="font_7"><strong>Your privacy</strong></p>
                    <p class="font_8">Under data protection legislation, we are required to provide you with certain information including who we are, how we process your personal data and for what purposes, your rights in relation to your personal data and how to exercise those rights. This information is provided in our <a href="https://www.rolecserv.com/rolec-smart-solutions/evconnect-privacy-policy" target="_self"><u>Privacy Policy</u></a> for this App and it is important that you read that information carefully.</p>
                    <p class="font_8"></p>
                    <p class="font_8">The ways in which you can use the App and Documentation may also be controlled by the rules and policies of any Appstore from which you downloaded the App, so you should also read their rules and policies carefully.</p>
                    <p class="font_8"></p>
                    <p class="font_7"><strong>Operating system requirements</strong></p>
                    <p class="font_8">The website or Appstore from which you downloaded the App will have set out clearly the operating system requirements, including the minimum amount of memory required and the types of operating system on which it can operate. Please check the applicable Appstore or website for updates on the operating system requirements.</p>
                    <p class="font_8"></p>
                    <p class="font_7"><strong>Support for the App and how to tell us about problems</strong></p>
                    <p class="font_8"><strong>Support.</strong> If you want to learn more about the App or the Service or have any problems using them please contact us.</p>
                    <p class="font_8"></p>
                    <p class="font_8"><strong>Contacting us (including with complaints).</strong> If you think the App or the Service is faulty or misdescribed or wish to contact us for any other reason please email our customer service team at <a href="mailto:technicalsupport@rolecserv.co.uk" target="_self"></a><a data-auto-recognition="true" href="mailto:technicalsupport@rolecserv.co.uk">technicalsupport@rolecserv.co.uk</a></p>
                    <p class="font_8"></p>
                    <p class="font_8"><strong>How we will communicate with you</strong>. If we have to contact you we will do so by email, by SMS or by pre-paid post, using the contact details you have provided to us.</p>
                    <p class="font_8"></p>
                    <p class="font_7"><strong>How you may use the App, including how many devices you may use it on</strong></p>
                    <p class="font_8">In return for your agreeing to comply with these terms you may:</p>
                    <p class="font_8"></p>
                    <ul class="font_9">
                    <li><p class="font_9">download or stream a copy of the App onto one device and view, use and display the App and the Service on such device for your personal purposes only.</p></li>
                    <li><p class="font_9">use any Documentation to support your permitted use of the App and the Service.</p></li>
                    <li><p class="font_9">receive and use any free supplementary software code or update of the App incorporating "patches" and corrections of errors as we may provide to you.</p></li>
                    </ul>
                    <p class="font_8"></p>
                    <p class="font_8">You must be 18 or over to accept these terms and download the App.</p>
                    <p class="font_8"></p>
                    <p class="font_7"><strong>Account creation</strong></p>
                    <p class="font_8">The App may only be accessed by using your unique account details. Unless you have these already (for example, where someone else within the company or entity on whose behalf you use the App has created the account for you), you will need to set your account up prior to obtaining access to the App.</p>
                    <p class="font_8"></p>
                    <p class="font_8">Where you create an Account but do not log into it for a 12 month period, we may close your Account either temporarily or permanently.</p>
                    <p class="font_8"></p>
                    <p class="font_7"><strong>You may not transfer the App to someone else</strong></p>
                    <p class="font_8">We are giving you personally the right to use the App and the Service as set out above. You may not transfer the App or the Service to someone else, whether for money, for anything else or for free. If you sell any device on which the App is installed, you must remove the App from it.</p>
                    <p class="font_8"></p>
                    <p class="font_7"><strong>Changes to these terms</strong></p>
                    <p class="font_8">We may need to change these terms, for example to reflect changes in law or best practice or to deal with additional features which we introduce. We will notify you of a change when you next start the App.</p>
                    <p class="font_8"></p>
                    <p class="font_8">If you do not accept the notified changes you will not be permitted to continue to use the App and the Service.</p>
                    <p class="font_8"></p>
                    <p class="font_7"><strong>Update to the App and changes to the Service</strong></p>
                    <p class="font_8">From time to time we may automatically update the App and change the Service to improve performance, enhance functionality, reflect changes to the operating system or address security issues. Alternatively we may ask you to update the App for these reasons.</p>
                    <p class="font_8"></p>
                    <p class="font_8">If you choose not to install such updates or if you opt out of automatic updates you may not be able to continue using the App and the Service.</p>
                    <p class="font_8"></p>
                    <p class="font_7"><strong>If someone else owns the phone or device you are using</strong></p>
                    <p class="font_8">If you download or stream the App onto any phone or other device not owned by you, you must have the owner's permission to do so. You will be responsible for complying with these terms, whether or not you own the phone or other device.</p>
                    <p class="font_8"></p>
                    <p class="font_7"><strong>We are not responsible for other apps or websites you link to</strong></p>
                    <p class="font_8">The App or any Service may contain links to other independent websites or apps which are not provided by us. Such independent sites or apps are not under our control, and we are not responsible for and have not checked and approved their content or their privacy policies (if any).</p>
                    <p class="font_8"></p>
                    <p class="font_8">For example, the App/Service does not operate to allow you to use or manage the operation of a Rolec Chargepoint – you would need to subscribe to a separate back-office platform for this purpose. Please refer to the terms of such platform provider for further details.</p>
                    <p class="font_8"></p>
                    <p class="font_8">You will need to make your own independent judgement about whether to use any such independent sites or apps, including whether to buy any products or services offered by them.</p>
                    <p class="font_8"></p>
                    <p class="font_7"><strong>Licence restrictions</strong></p>
                    <p class="font_8">You agree that you will:</p>
                    <p class="font_8"></p>
                    <ul class="font_9">
                    <li><p class="font_9">not rent, lease, sub-license, loan, provide, or otherwise make available, the App or the Services in any form, in whole or in part to any person without prior written consent from us;</p></li>
                    <li><p class="font_9">not copy the App, Documentation or Services, except as part of the normal use of the App;</p></li>
                    <li><p class="font_9">not translate, merge, adapt, vary, alter or modify, the whole or any part of the App, Documentation or Services nor permit the App or the Services or any part of them to be combined with, or become incorporated in, any other programs, except as necessary to use the App and the Services on devices as permitted in these terms;</p></li>
                    <li><p class="font_9">not disassemble, de-compile, reverse engineer or create derivative works based on the whole or any part of the App or the Services nor attempt to do any such things, except to the extent that (by virtue of sections 50B and 296A of the Copyright, Designs and Patents Act 1988) such actions cannot be prohibited because they are necessary to decompile the App to obtain the information necessary to create an independent program that can be operated with the App or with another program (<strong>Permitted Objective</strong>), and provided that the information obtained by you during such activities:</p></li>
                    <li><p class="font_9">is not disclosed or communicated without the Licensor's prior written consent to any third party to whom it is not necessary to disclose or communicate it in order to achieve the Permitted Objective; and</p></li>
                    <li><p class="font_9">is not used to create any software that is substantially similar in its expression to the App;</p></li>
                    <li><p class="font_9">is kept secure; and</p></li>
                    <li><p class="font_9">is used only for the Permitted Objective;</p></li>
                    <li><p class="font_9">comply with all applicable technology control or export laws and regulations that apply to the technology used or supported by the App or any Service.</p></li>
                    </ul>
                    <p class="font_8"></p>
                    <p class="font_7"><strong>Acceptable use restrictions</strong></p>
                    <p class="font_8">You must:</p>
                    <p class="font_8"></p>
                    <ul class="font_9">
                    <li><p class="font_9">not use the App or any Service in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these terms, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, such as viruses, or harmful data, into the App, any Service or any operating system;</p></li>
                    <li><p class="font_9">not infringe our intellectual property rights or those of any third party in relation to your use of the App or any Service, including by the submission of any material (to the extent that such use is not licensed by these terms);</p></li>
                    <li><p class="font_9">not transmit any material that is defamatory, offensive or otherwise objectionable in relation to your use of the App or any Service;</p></li>
                    <li><p class="font_9">not use the App or any Service in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users; and</p></li>
                    <li><p class="font_9">not collect or harvest any information or data from any Service or our systems or attempt to decipher any transmissions to or from the servers running any Service.</p></li>
                    </ul>
                    <p class="font_8"></p>
                    <p class="font_7"><strong>Intellectual property rights</strong></p>
                    <p class="font_8">All intellectual property rights in the App, the Documentation and the Service throughout the world belong to us (or our licensors) and the rights in the App and the Service are licensed (not sold) to you. You have no intellectual property rights in, or to, the App, the Documentation or the Service other than the right to use them in accordance with these terms.</p>
                    <p class="font_8"></p>
                    <p class="font_7"><strong>Our responsibility for loss or damage suffered by you</strong></p>
                    <p class="font_8"><strong>You are responsible for how you use the App and Service</strong>. In accessing this version of the App, you have warranted to us that you are a suitably qualified/trained, skilled, knowledgeable and experienced person to perform the work you are performing via (or with the support of) the App and Service. By giving this warranty to us, you have been permitted to access a version of the App which allows you to configure various settings which, if improperly configured, could create a serious risk of harm or damage to both person and property. Any such harm or damage caused by your improper or imperfect installation or configuration work is entirely your responsibility, and you indemnify us from and against any liability for such harm (whether such liability arises under contract, tort (including negligence) or any other legal theory).</p>
                    <p class="font_8"></p>
                    <p class="font_8"><strong>We are never responsible for certain types of loss</strong>. We are never liable to you for any of the following, whether such liability arises under contract, tort (including negligence) or any other legal theory, and in each case except the final bullet point whether the relevant loss or damage is direct or indirect:</p>
                    <p class="font_8"></p>
                    <ul class="font_9">
                    <li><p class="font_9">loss of profits or revenue;</p></li>
                    <li><p class="font_9">loss of sales or business;</p></li>
                    <li><p class="font_9">loss of anticipated savings or increase in costs;</p></li>
                    <li><p class="font_9">loss or corruption of data or information;</p></li>
                    <li><p class="font_9">loss of business opportunity, goodwill or reputation; or</p></li>
                    <li><p class="font_9">indirect or consequential loss or damage.</p></li>
                    </ul>
                    <p class="font_8"></p>
                    <p class="font_8"><strong>We are not liable for loss or damage arising from third party services.</strong> We are not responsible for any loss or damage you suffer in respect of third party services, apps, software platforms or websites.</p>
                    <p class="font_8"></p>
                    <p class="font_8"><strong>Our maximum liability to you.</strong> Other than the losses set out in the two preceding paragraphs (for which we are not liable), and subject to the paragraph below, our maximum aggregate liability under or in connection with the App or Service, whether such liability arises under contract, tort (including negligence) or any other legal theory, shall not exceed £20,000. For the avoidance of doubt, where multiple licences to use the App/Service are in place to individuals using the App/Service on behalf of the same company or entity (or those within the same corporate group), then this shall be the aggregate liability cap between all such licences.</p>
                    <p class="font_8"></p>
                    <p class="font_8"><strong>We do not exclude or limit in any way our liability to you where it would be unlawful to do so</strong>. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors, or for fraud or fraudulent misrepresentation.</p>
                    <p class="font_8"></p>
                    <p class="font_8"><strong>Limitations to the App and the Service</strong>. The App and the Service are provided for general information and facilitation purposes only. They do not offer advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of information obtained from the App or the Service. Although we make reasonable efforts to update the information provided by the App and the Service, we make no representations, warranties or guarantees, whether express or implied, that such information is accurate, complete or up to date.</p>
                    <p class="font_8"></p>
                    <p class="font_8"><strong>Please back-up content and data used with the App</strong>. We recommend that you back up any content and data used in connection with the App, to protect yourself in case of problems with the App or the Service.</p>
                    <p class="font_8"></p>
                    <p class="font_8"><strong>Check that the App and the Service are suitable for you</strong>. The App and the Service have not been developed to meet your individual requirements. Please check that the facilities and functions of the App and the Service (as described on the appstore site and in the Documentation) meet your requirements.</p>
                    <p class="font_8"></p>
                    <p class="font_8"><strong>We are not responsible for events outside our control.</strong> If our provision of the Service or support for the App or the Service is delayed by an event outside our control then we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay. We will have no other liability whatsoever for any such delay.</p>
                    <p class="font_8"></p>
                    <p class="font_8"><strong>We may end your rights to use the App and the Service if you break these terms</strong></p>
                    <p class="font_8">We may end your rights to use the App and Service at any time by contacting you if you have broken these terms in a serious way. If what you have done can be put right we may (at our discretion) give you a reasonable opportunity to do so.</p>
                    <p class="font_8"></p>
                    <p class="font_8">If we end your rights to use the App and Service:</p>
                    <p class="font_8"></p>
                    <ul class="font_9">
                    <li><p class="font_9">You must stop all activities authorised by these terms, including your use of the App and any Service.</p></li>
                    <li><p class="font_9">You must delete or remove the App from all devices in your possession and immediately destroy all copies of the App which you have and confirm to us that you have done this.</p></li>
                    <li><p class="font_9">We may remotely access your devices and remove the App from them and cease providing you with access to the Service.</p></li>
                    </ul>
                    <p class="font_8"></p>
                    <p class="font_7"><strong>Transfer of this Agreement</strong></p>
                    <p class="font_8">We may transfer our rights and obligations under these terms to another organisation. We will always tell you in writing (including email or SMS) if this happens and we will ensure that the transfer will not affect your rights under this Agreement. You may only transfer your rights or your obligations under these terms to another person if we agree in writing.</p>
                    <p class="font_8"></p>
                    <p class="font_7"><strong>No rights for third parties</strong></p>
                    <p class="font_8">This Agreement does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this Agreement.</p>
                    <p class="font_8"></p>
                    <p class="font_7"><strong>Illegality and enforcement</strong></p>
                    <p class="font_8">Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.</p>
                    <p class="font_8">Even if we delay in enforcing this contract, we can still enforce it later. If we do not insist immediately that you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.</p>
                    <p class="font_8"></p>
                    <p class="font_7"><strong>Which laws apply to this contract and where you may bring legal proceedings</strong></p>
                    <p class="font_8">These terms are governed by English law and the English courts have exclusive jurisdiction over any and all disputes in respect of these terms, the App and/or the Service.</p>

                </Box>

                <Box style={{display: 'flex', flex: 1, justifyContent: 'space-between', paddingTop: 25}}>
                    <Button onClick={handleDisagree}>I do not accept</Button>
                    <Button variant="contained" onClick={handleAgree} color="primary">I ACCEPT</Button>
                </Box>
            </DialogContent>

        </Dialog>

        </React.Fragment>
    )

}

export default InstallerEndUserLicenceAgreement
