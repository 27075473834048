import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, useTheme, Button, List, ListItem, ListItemText, Typography, CircularProgress, Box, IconButton, Paper} from '@material-ui/core';
import PageToolbar from '../../components/pageToolbar';
import globalContext from '../../context/globalContext';
import SideSlidePanel from '../../components/sideSlidePanel';
import ProjectAdd from '../../content/projectAdd';
import NoChargePoints from '../../components/noChargePoints';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RefreshIcon from '@material-ui/icons/Refresh';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ProjectAddExisting from '../../content/projectAddExisting';
import { useHistory } from 'react-router-dom';
import SearchField from '../../components/searchField';
import SearchResultProject from '../../content/searchResultProject';
import API from '../../global/api';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  busyBox: {
    height: 300, 
    width: '100%', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center'
  }
}))


const ProjectsCommercial = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);
  const history = useHistory();

  const [showAdd, setShowAdd] = useState(false);
  const [showMore, setShowMore] = useState(false);

  useEffect(() =>{
    context.projectsLoad();
  }, [])

  const projects = context.project.data.filter(p => p.project_type === 'COMM' && [1,2].indexOf(p.id_project_status) > -1);

  const handleChangeRoute = route => {
    history.push(route);
  } 

  const handleSearch = async search => {
    
    try {

      const res = await API.req(`/projects/search?s=${search}`, 'GET');
      return res.result
      
    } catch (error) {
      console.log(error);
      return []
    }

  }

  return (

    <div className={classes.container}>

      <PageToolbar title="Commercial Projects" subtitle="Current">
        <SearchField onSearch={handleSearch} resultComponent={r => <SearchResultProject result={r} />}/>
        <IconButton onClick={context.projectsLoad} color="primary">{context.project.status === 'LOADING' ? <CircularProgress size={18} /> : <RefreshIcon />}</IconButton>
        <IconButton onClick={() => setShowMore(true)} color="primary"><AddCircleIcon /></IconButton>
        <Button variant="contained" color="primary" onClick={() => setShowAdd(true)}>NEW</Button>
      </PageToolbar>

      <Paper style={{padding: 20}}>

        {(context.project.status !== 'LOADING' && projects.length > 0) &&
          <List>
            {projects.map((p, idx) => (
              <ListItem button key={p.project_code} style={{borderTop: idx === 0 ? 'none' : '1px solid #c4c4c4'}} onClick={() => handleChangeRoute(`/project/${p.project_code}`)}>
                <ListItemText primary={p.project_name} secondary={`${new Date(p.created_on).toLocaleDateString('en-GB')} - ${p.project_status}`} />
                <Typography variant="h3">{p.project_code}</Typography>
              </ListItem>
            ))}
          </List>
        }

        {context.project.status === 'LOADING' && <Box className={classes.busyBox}><CircularProgress size={36} /></Box>}
        {(context.project.status !== 'LOADING' && projects.length === 0) && 
          <Box className={classes.busyBox}>
              <NoChargePoints label="You have no projects" point={null} subLabel="Tap 'NEW' to create a new project" />
          </Box>
        } 

      </Paper>
      
      <SideSlidePanel show={showAdd} handleClose={() => setShowAdd(false)} title="New Project" side="RIGHT"><ProjectAdd onSuccess={() => setShowAdd(false)} /></SideSlidePanel>
      <SideSlidePanel show={showMore} handleClose={() => setShowMore(false)} title="Add Existing Project" side="RIGHT"><ProjectAddExisting onSuccess={() => setShowMore(false)} /></SideSlidePanel>

    </div>
  );
}

export default ProjectsCommercial;
