import React from 'react';
import {Button, useTheme, CircularProgress, makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  gutterBottom: {
    marginBottom: 10
  },
  nothing: {}
}))

const ProgressButton = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const {waiting, variant, color, fullWidth, onClick, size, gutterBottom} = props;

  const isDisabled = () => {
    if(typeof props.disabled !== 'undefined' && props.disabled === true)
      return true;
    
    if(typeof props.waiting !== 'undefined' && props.waiting === true)
      return true;

    return false;
  }

  return (
    <Button 
      disabled={isDisabled()} 
      variant={variant || 'default'} 
      color={color || 'default'} 
      fullWidth={fullWidth || false} 
      onClick={onClick}
      size={size || 'medium'}
      classes={{
        root: gutterBottom === true ? classes.gutterBottom : classes.nothing
      }}
      >
        {!waiting && props.children}
        {waiting && <CircularProgress color='inherit' size={20} style={{margin: '0 10px'}}/>}
    </Button>
  );
}

export default ProgressButton;
