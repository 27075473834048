import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, {useContext, useState} from 'react';
import ProgressButton from '../components/progressButton';
import globalContext from '../context/globalContext';
import API from '../global/api';


const TeamMemberInviteDelete = props => {

  const context = useContext(globalContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const invite = props.invite;



  const handleSubmit = async () => {

    try {
          setError(null);
          setLoading(true);
          const deleteTeamMemberInvite = await API.req(`/team/${invite.id_team}/invite/${invite.invite_code}`, 'DELETE');
          setLoading(false);
          if(deleteTeamMemberInvite.success){
            if(typeof props.onSuccess === 'function')
              props.onSuccess();
          } else{
            setError(deleteTeamMemberInvite.msg)
          }
      
    } catch (error) {
      setLoading(false);
      setError(typeof error.msg !== 'undefined' ? error.msg : JSON.stringify(error))
    }

  }

  

  const handleClose = () => {
    setError(null);
    if(typeof props.onClose === 'function')
      props.onClose();
  }


  return (
    <React.Fragment>

      <Dialog open={props.show} fullWidth maxWidth="xs" onClose={handleClose}>

        <DialogTitle>Delete Team Invite <b>{invite?.email}</b></DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <Typography variant="body1">Confirm you wish to delete the team invite for "{invite?.email}".</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ProgressButton variant="contained" onClick={handleSubmit} color="primary" waiting={loading}>CONFIRM</ProgressButton>
        </DialogActions>


      </Dialog>

    </React.Fragment>
  )

}

export default TeamMemberInviteDelete

