export function chargepointsAdd (payload) {
    return dispatch => {

        // Loading
        dispatch({
            type: 'CHARGEPOINTS_ADD',
            status: 'OK',
            payload: payload
        })

        return 'success';

    }
}

export function chargepointUpdate (payload) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'CHARGEPOINT_UPDATE',
            status: 'OK',
            payload: payload
        })
        
    }
}

export function chargepointRemove (payload) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'CHARGEPOINT_REMOVE',
            status: 'OK',
            payload: payload
        })
        
    }
}


export function chargepointsClear () {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'CHARGEPOINTS_CLEAR',
            status: 'OK',
            payload: null
        })
        
    }
}



export function chargepointSelect (payload) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'CHARGEPOINT_SELECT',
            status: 'OK',
            payload: payload
        })
        
    }
}



export function chargepointSelectOne (payload) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'CHARGEPOINT_SELECTONE',
            status: 'OK',
            payload: payload
        })
        
    }
}



export function chargepointUnselect (payload) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'CHARGEPOINT_UNSELECT',
            status: 'OK',
            payload: payload
        })
        
    }
}



export function chargepointsClearSelected () {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'CHARGEPOINTS_SELECTED_CLEAR',
            status: 'OK',
            payload: null
        })
        
    }
}