import { Box, Chip} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, {useState, useEffect} from 'react';
import InputField from '../components/inputField';
import API from '../global/api';
import { makeValidation, validateForm } from '../global/formHelper';


const ProjectChargePointsSelect = props => {

  const {project_code} = props;

  const [chargepoints, setChargepoints] = useState([]);
  const [availableChargepoints, setAvailableChargepoints] = useState([]);
  const [error, setError] = useState(null);

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'chargepoints',
      display_name: 'Chargepoints',
      required: true
    }
  ]))

  const handleChargepointsChange = e => {
    let newChargpoints = JSON.parse(JSON.stringify(chargepoints));
    if(newChargpoints.indexOf(e.target.value) > -1)
      newChargpoints.splice(newChargpoints.indexOf(e.target.value), 1);
    else
      newChargpoints.push(e.target.value);

    setChargepoints(newChargpoints);
  }

  const handleChargepointsDelete = cp => {
    let newChargpoints = JSON.parse(JSON.stringify(chargepoints));
    if(newChargpoints.indexOf(cp) > -1){
      newChargpoints.splice(newChargpoints.indexOf(cp), 1);
      setChargepoints(newChargpoints);
    }
  }

  useEffect(() => {
    setValidation(validateForm(chargepoints, validation))
    if(typeof props.handleChange === 'function')
      props.handleChange(chargepoints);
  }, [chargepoints])


  const loadChargePoints = async () => {

    try {

        setError(null);

        const getChargePoints = await API.req(`/chargepoints/${project_code}`, 'GET');

        if(!getChargePoints.success)
            throw getChargePoints;

        let cps = [];
        getChargePoints.result.map(cp => cps.push({key: cp.cpid, value: cp.cpid}));
        setAvailableChargepoints(cps);

        
    } catch (error) {
        setError('ERR_LOADING_CHARGEPOINTS');
    }

  }
  
  useEffect(() =>{
    loadChargePoints()
  }, [project_code])
  

  return (
    <React.Fragment>
      <Box style={{marginBottom: 20, marginTop: 20}}>
        {error && <Alert severity="error">{error}</Alert> } 
        <InputField type="select" hoverLabel={true} name="chargepoints" validation={validation.chargepoints} value={chargepoints} handleChange={handleChargepointsChange} items={availableChargepoints} />  
        <Box style={{display: 'flex', flexDirection: 'row', flexWrap:'wrap', gap: '10px'}}>
          {chargepoints.map((cp, idx) => (
            <Chip label={cp} color="primary" onDelete={() => handleChargepointsDelete(cp)}/>
          ))}
        </Box>
      </Box>
    </React.Fragment>
  )

}

export default ProjectChargePointsSelect

