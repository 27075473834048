import React, { useState} from 'react';
import { ListItem, ListItemText, IconButton} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import Zura from '../components/zura';
import ProjectSiteChargepointDelete from './projectSiteChargepointDelete';
import ChargepointIcon from '../components/chargepointIcon';

const ProjectSiteChargepoint = props => {

  const {id_project_site, id_project, chargepoint} = props;

  const [showDelete, setShowDelete] = useState(false);

  const handleDeleteSuccess = () => {
    setShowDelete(false);
    if(typeof props.onDelete === 'function')
      props.onDelete();
  }
  
  return (
    <React.Fragment>

      {chargepoint && 
        <React.Fragment>

          <ListItem>
            <ChargepointIcon type={chargepoint.unit_type_code} style={{marginRight: 10}}/>
            <ListItemText primary={chargepoint.cpid} secondary={chargepoint.status} />
            <IconButton color="primary" onClick={() => setShowDelete(true)}><DeleteIcon /></IconButton>
          </ListItem>

          <ProjectSiteChargepointDelete id_project_site={id_project_site} id_project={id_project} id_chargepoint={chargepoint.id_chargepoint} show={showDelete} onClose={() => setShowDelete(false)} onSuccess={handleDeleteSuccess}/>

        </React.Fragment>
      }

    </React.Fragment>
  )

}

export default ProjectSiteChargepoint