/*

    The structure of a charge point within the chargepoints reducer
    
    data = [
        {
            cpid: 'UKEV24721',
            eMI3: 'GB*VEN*EUKEV24721',
            status: 'UNCONFIGURED',
            created_on: 2022-08-01 09:02:14,
            created_by: 33,
            ...
            configurations: [
                {
                    id_chargepoint_configuration: 11,
                    timestamp: 2022-08-01 09:02:14,
                    configuration: "[{"key":"Identity","value":""},{"key":"NumberOfConnectors","value":""},{"key":"HeartbeatInterval","value":""}]",
                    id_user: 33
                }
                ...
            ],
            pending_configurations: [
                {
                    timestamp: 2022-08-02 10:12:24,
                    configuration: "[{"key":"Identity","value":""},{"key":"NumberOfConnectors","value":""},{"key":"HeartbeatInterval","value":""}]"
                }
            ]
        }
    ]


*/


const chargePointsReducer = (state = {
    status: 'LOADING',
    msg: '',
    data: [],
    selected: []
}, action) => {
    let current_data = JSON.parse(JSON.stringify(state.data));
    const status = typeof action.status !== 'undefined' ? action.status.toUpperCase(): null;
    const idx = typeof action.payload?.id_chargepoint !== 'undefined' ? current_data.findIndex(c => c.id_chargepoint === action.payload.id_chargepoint) : -1;

    switch(action.type){
        case "CHARGEPOINTS_ADD":
            state = {
                ...state,
                status: status,
                msg: status === 'ERROR'?action.payload:'',
                data: status === 'OK'?mergeData(current_data, action.payload):current_data
            }
            break;
        case "CHARGEPOINT_UPDATE":
            if(status === 'OK' && idx > -1)
                current_data[idx] = action.payload;
            else
                current_data.push(action.payload);
                
            state = {
                ...state,
                status: status,
                msg: status === 'ERROR'?action.payload:'',
                data: current_data
            }
            break;
        case "CHARGEPOINT_REMOVE":
            if(status === 'OK' && idx > -1)
                current_data.splice(idx, 1);
                
            state = {
                ...state,
                status: status,
                msg: status === 'ERROR'?action.payload:'',
                data: current_data
            }
            break;
        case "CHARGEPOINTS_CLEAR":
            state = {
                status: 'LOADING',
                msg: '',
                data: [],
                selected: []
            }
            break;
        case "CHARGEPOINT_SELECT":
            if(status === 'OK' && idx > -1 && state.selected.indexOf(action.payload.id_chargepoint) === -1)
                state.selected.push(action.payload.id_chargepoint)
            break;
        case "CHARGEPOINT_SELECTONE":
            if(status === 'OK' && idx > -1 && state.selected.indexOf(action.payload.id_chargepoint) === -1)
                state.selected = [action.payload.id_chargepoint]
            else
                state.selected = [];
            break;
        case "CHARGEPOINT_UNSELECT":
            if(status === 'OK' && idx > -1 && state.selected.indexOf(action.payload.id_chargepoint) > -1)
                state.selected.splice(state.selected.indexOf(action.payload.id_chargepoint), 1)
            break;
        case "CHARGEPOINTS_SELECTED_CLEAR":
            console.log('cleared')
            state = {
                ...state,
                selected: []
            }
            break;
        default:
    }
    return state;
}

const mergeData = (current_data, new_data) => {

    if(current_data.length > 0) {

        new_data.forEach((c, idx) => {
            const cidx = current_data.findIndex(cp => cp.id_chargepoint === c.id_chargepoint);
    
            if(cidx > -1){
                new_data[idx].pending_configurations = current_data[cidx].pending_configurations;   
                current_data.splice(cidx, 1);
            }

        });

        return [
            ...current_data,
            ...new_data
        ]

    }

    return new_data;

}

export default chargePointsReducer;