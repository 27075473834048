import React, {useState, useRef, useEffect} from 'react';
import {Typography, Button, CircularProgress, useTheme, makeStyles, Box} from '@material-ui/core';

import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import API from '../global/api';



const useStyles = makeStyles(theme => ({
    dropArea: {
        marginTop: 8,
        marginBottom: 8,
        padding: 8, 
        backgroundColor: theme.palette.action.hover, 
        border: `3px solid ${theme.palette.action.focus}`,
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    dropAreaHighlight: {
        marginTop: 8,
        marginBottom: 8,
        padding: 8, 
        backgroundColor: theme.palette.action.focus, 
        border: `3px solid ${theme.palette.action.selected}`,
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    message: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'center', 
        alignItems: 'center'
    }
}));



const ChargePointPhotoUpload = props => {

    const theme = useTheme();
    const styles = useStyles(theme);

    const {chargepoint} = props

    const [ dropHighlight, setDropHighlight ] = useState(false)
    const [ fileUpload, setFileUpload ] = useState(null);
    const [ uploading, setUploading ] = useState(false);

    const dropArea = useRef(null);

    useEffect(() => {

        const preventDefaults = (e) => {
            e.preventDefault()
            e.stopPropagation()
        }

        dropArea.current.addEventListener('dragenter', e => {
            preventDefaults(e);
            setDropHighlight(true);
        }, false)

        dropArea.current.addEventListener('dragover', e => {
            preventDefaults(e);
            setDropHighlight(true);
        }, false)

        dropArea.current.addEventListener('dragleave', e => {
            preventDefaults(e);
            setDropHighlight(false);
        }, false)

        dropArea.current.addEventListener('drop', e => {
            preventDefaults(e);
            setDropHighlight(false);
            setFileUpload(e.dataTransfer.files[0]);
        }, false)

    }, [])


    const handleFileChange = e => {
        setFileUpload(e.target.files[0]);
    }

    const handleUpload = () => {
        setUploading(true);

        API.fileUpload(`/chargepoint/${chargepoint.id_chargepoint}/attach`, {attachment: fileUpload}).then(
            res => {
                setUploading(false);
                setFileUpload(null);
                if(typeof props.onUpload === 'function')
                    props.onUpload();
                console.log(res);
            },
            err => {
                setUploading(false);
                console.log(err);
            }
        )

    }

    const harmfulUpload = () => {
        if(fileUpload !== null && fileUpload.name.substring(fileUpload.name.indexOf('.'), fileUpload.name.length) === '.msg')
            return true;
        
        return false;
    }



    return (
            
        <Box ref={dropArea} className={dropHighlight ? styles.dropAreaHighlight : styles.dropArea}>
            {fileUpload === null && 
                <React.Fragment>
                    {!dropHighlight && <Button
                        component="label"
                        disabled={uploading}
                        >
                        <VerticalAlignBottomIcon size="small" /> Choose a file or drag it here
                        <input
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                        />
                        {uploading && <CircularProgress size={24} />}
                    </Button>}
                    {dropHighlight && 
                        <Box style={{paddingTop: 6, paddingBottom: 6, paddingLeft: 8, paddingRight: 8}}>
                            <Typography variant="button" className={styles.row}><VerticalAlignBottomIcon size="small" /> DROP</Typography>
                        </Box>
                    }
                </React.Fragment>
            }
        
            {fileUpload !== null && 
            <Box style={{width: '100%', paddingTop: 6, paddingBottom: 6, paddingLeft: 8, paddingRight: 8}}>
                <Typography variant="subtitle1" style={{flex: 1}}>Confirm upload</Typography>
                <Typography variant="caption" style={{flex: 1}}>{fileUpload.name}</Typography>
                {harmfulUpload() && <Typography color="error" variant="caption" component='div'>Be careful, emails can contain malicious attachments or links.</Typography>}
                <Box style={{width: '100%', display: 'flex', flexDirection: 'column', gap: '6px'}}>
                    {!uploading && 
                        <Box style={{display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: 6}}>
                            <Button onClick={() => setFileUpload(null)} size="small">Cancel</Button>
                            <Button color="primary" variant="contained" onClick={handleUpload} size="small">Upload</Button>
                        </Box>
                    }
                    {uploading && <CircularProgress size={24} />}
                </Box>
            </Box>}
        </Box>

    )
}

export default ChargePointPhotoUpload