import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, {useContext, useState} from 'react';
import { useHistory } from 'react-router-dom';
import ProgressButton from '../components/progressButton';
import globalContext from '../context/globalContext';
import API from '../global/api';


const ProjectDelete = props => {

  const context = useContext(globalContext);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const project = props.project;


  const handleSubmit = async () => {

    try {
          setError(null);
          setLoading(true);
          const deleteProject = await API.req(`/project/${project.id_project}`, 'DELETE');
          setLoading(false);
          if(deleteProject.success){
            context.projectRemove(project);
            history.push(`/projects/${project.project_type === 'COMM' ? 'commercial' : 'home'}`)
          } else{
            setError(deleteProject.msg)
          }
      
    } catch (error) {
      setLoading(false);
      setError(typeof error.msg !== 'undefined' ? error.msg : JSON.stringify(error))
    }

  }

  

  const handleClose = () => {
    setError(null);
    if(typeof props.onClose === 'function')
      props.onClose();
  }


  return (
    <React.Fragment>

      <Dialog open={props.show} fullWidth maxWidth="xs" onClose={handleClose}>

        <DialogTitle>Delete Project <b>{project?.project_code}</b></DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <Typography variant="body1">Confirm you wish to delete project "{project?.project_name}". Once complete, this action is unrecoverable.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ProgressButton variant="contained" onClick={handleSubmit} color="primary" waiting={loading}>CONFIRM</ProgressButton>
        </DialogActions>


      </Dialog>

    </React.Fragment>
  )

}

export default ProjectDelete

