import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, useTheme, Typography, CircularProgress, Box, CardActionArea, Card} from '@material-ui/core';
import globalContext from '../context/globalContext';
import NoChargePoints from '../components/noChargePoints';
import API from '../global/api';
import Zura from '../components/zura';
import { SQLtoUTCDate } from '../global/dateTimeHelpers';
import SideSlidePanel from '../components/sideSlidePanel';
import ChargepointMore from './chargepointMore';
import { Alert } from '@material-ui/lab';
import ChargepointIcon from '../components/chargepointIcon';

const useStyles = makeStyles(theme => ({
  busyBox: {
    height: 300, 
    width: '100%', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center'
  }
}))


const ProjectChargePoints = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const {project_code} = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [chargepoints, setChargepoints] = useState(null);
  const [selectChargePoint, setSelectChargePoint] = useState(false);



  const loadChargePoints = async () => {

    try {

        setError(null);
        setLoading(true);

        const getChargePoints = await API.req(`/chargepoints/${project_code}`, 'GET');

        if(!getChargePoints.success)
            throw getChargePoints;

        context.chargepointsAdd(getChargePoints.result);
        setChargepoints(getChargePoints.result);

        setLoading(false);

        
    } catch (error) {
        setError('ERR_LOADING_CHARGEPOINTS');
    }

  }
  
  useEffect(() =>{
    loadChargePoints()
  }, [])

  return (

    <React.Fragment>
      
      <Box style={{paddingTop: 30}}>
        <Typography variant="h6">Chargepoints</Typography>

        {loading && <Box className={classes.busyBox}><CircularProgress size={36} /></Box>}
        {(!loading && chargepoints !== null && chargepoints.length === 0) && 
          <Box className={classes.busyBox}>
              <NoChargePoints label="You have no charge points" point={null} subLabel="Use the EV Connect app to scan and add new charge points" />
          </Box>
        } 
        {error && <Alert severity="error">{error}</Alert> } 
        
        {(!loading && chargepoints !== null && chargepoints.length > 0) && 
          <Box className='flex-row-start pt' style={{flexWrap: 'wrap'}}>
            {chargepoints.map((cp, idx) => (
              <Card key={idx} style={{minWidth: 300}}>
                <CardActionArea onClick={() => setSelectChargePoint(cp.id_chargepoint)}>
                  <Box style={{display: 'flex', gap: 20, padding: 30}}>
                    <ChargepointIcon type={cp.unit_type_code} />
                    <Box style={{display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="subtitle2" color="primary">{cp.cpid}</Typography>
                        <Typography variant="caption" component="div">Added: {SQLtoUTCDate(cp.created_on, false)}</Typography>
                        <Typography variant="caption" component="div">Status: {cp.status}</Typography>
                    </Box>
                  </Box>
                </CardActionArea>
              </Card>
            ))}
          </Box>
        } 
      </Box>

      <SideSlidePanel show={selectChargePoint} handleClose={() => setSelectChargePoint(false)} title="Chargepoint" side="RIGHT"><ChargepointMore id_chargepoint={selectChargePoint} /></SideSlidePanel>

    </React.Fragment>

  );
}

export default ProjectChargePoints;
