import { Box, Typography } from '@material-ui/core';
import React from 'react';
import Images from '../assets/imgs';

const NoSites = props => {

  const label = props.label || null;
  const subLabel = props.subLabel || null;

  return (
    <React.Fragment>

      <Box style={{display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center', justifyContent: 'center'}}>

        <Box style={{height: 120, width: 'auto'}}>
          <img src={Images.icon_location} alt="No Sites" style={{height: '100%', width: 'auto'}} />
        </Box>
        {label && <Typography variant="h6" color="textSecondary">{label}</Typography>}
        {subLabel && <Typography variant="caption" color="textSecondary">{subLabel}</Typography>}

      </Box>

    </React.Fragment>
  )
}

export default NoSites