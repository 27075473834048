import { InputBase, IconButton, Box, useTheme, List, Typography, CircularProgress, ClickAwayListener } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import React, {useState} from "react";


const SearchField = props => {

  const {onSearch, resultComponent} = props;

  const theme = useTheme();

  var typeTimer;

  const [search, setSearch] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  const handleSearchChange = e => {
    setSearch(e.target.value);
    setShowResults(e.target.value.length > 0);

    if(typeof typeTimer !== 'undefined')
      clearTimeout(typeTimer);
    
     if(e.target.value.length > 0)
      typeTimer = setTimeout(() => handleSearch(e.target.value), 750);
  }

  const handleSearch = async (str) => {

    setLoading(true);
    try {

      const searchResults = await onSearch(str);
      if(typeof searchResults !== 'undefined')
        setResults(searchResults);

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleClickAway = () => {
    setShowResults(false);
  }

  const handleClickIn = () => {
    if(search.length > 0)
      setShowResults(true);
  }

  const handleClear = () => {

    setSearch('');
    setShowResults(false);
    setLoading(false);
    setResults([]);

  }

  return (
    <React.Fragment>

      <ClickAwayListener onClickAway={handleClickAway}>
        <Box
          component="form"
          style={{ 
            padding: '2px 4px ', 
            display: 'flex', 
            alignItems: 'center',
            minWidth: 200, 
            backgroundColor: theme.palette.background.paper, 
            borderRadius: 30, 
            border: `1px solid ${theme.palette.primary.main}`,
            position: 'relative'
          }}
        >

          <InputBase
            style={{ marginLeft: 16, flex: 1 }}
            placeholder={props.placeholder || 'Search'}
            inputProps={{ 'aria-label': 'search' }} 
            value={search} 
            onChange={handleSearchChange}
            onFocus={handleClickIn}
          />

          {search.length > 0 && 
            <IconButton type="button" style={{ padding: '10px' }} aria-label="search" onClick={handleClear}>
              <CloseIcon />
            </IconButton>
          }

          {search.length === 0 &&
            <IconButton type="button" style={{ padding: '10px' }} aria-label="search" onClick={handleSearch}>
              <SearchIcon />
            </IconButton>
          }

          {showResults &&  
            <Box style={{
              display: 'block',
              minHeight: 100,
              width: '140%',
              position: 'absolute',
              zIndex: 90,
              backgroundColor: theme.palette.background.paper, 
              borderRadius: 30, 
              border: `1px solid ${theme.palette.primary.main}`, 
              left: 0,
              top: '120%'
            }}>
              <Box style={{padding: 16}}>
                {loading && <CircularProgress size={20} />}
                {(!loading && results.length === 0) && <Typography variant="body1" color="textSecondary">No results found</Typography>}
                {(!loading && results.length > 0 && props.resultComponent !== 'undefined') && 
                  <List>
                    {results.map((r, idx) => (
                      <React.Fragment key={idx}>
                        {resultComponent(r)}
                      </React.Fragment>
                    ))}
                  </List>
                }
              </Box>
            </Box>
          }


        </Box>
      </ClickAwayListener>

    </React.Fragment>
  )
}

export default SearchField;