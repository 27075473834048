import { Box } from '@material-ui/core';
import React from 'react';
import Images from '../assets/imgs';

const Arrow = props => {

  const size = props.size || 80;
  const deg = props.point || 0;
  const marginBottom = (deg > 89 && deg < 271) ? -((size-10)) : 0;
  const marginRight = (deg > 89 && deg < 179) ? (size/2) : 0;
  const marginLeft = (deg > 181 && deg < 271) ? (size/2) : 0;

  return (
    <React.Fragment>

      <Box style={{height: size, width: 'auto', transform: `rotate(${deg}deg)`, transformOrigin: 'top center', marginBottom: marginBottom, marginLeft: marginLeft, marginRight: marginRight}}>
        <img src={Images.arrow} alt="arrow" style={{height: '100%', width: 'auto'}} />
      </Box>

    </React.Fragment>
  )
}

export default Arrow