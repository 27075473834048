import React, { useContext, useEffect, useState} from 'react';
import { Box, Typography, List, ListItem, ListItemText, IconButton, ListItemIcon, Button} from '@material-ui/core'
import globalContext from '../context/globalContext';
import Alert from '@material-ui/lab/Alert';
import Images from '../assets/imgs';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ProjectSiteDelete from './projectSiteDelete';
import API from '../global/api';
import ProjectSiteChargepoint from './projectSiteChargepoint';
import ProjectSiteChargepointAdd from './projectSiteChargepointAdd';

const ProjectSiteMore = props => {

  const context = useContext(globalContext);
  const {site} = props;

  const [chargepoints, setChargepoints] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showAddChargepoint, setShowAddChargepoint] = useState(false);

  const loadChargePoints = async () => {

    try {
    
      const getChargepoints = await API.req(`/project/${site.id_project}/site/${site.id_project_site}/chargepoints`, 'GET');
      if(!getChargepoints.success)
          throw getChargepoints;

      let id_chargepoints = [];
      getChargepoints.result.map(cp => id_chargepoints.push(cp.id_chargepoint));

      const cps = context.chargepoints.data.filter(cp => id_chargepoints.indexOf(cp.id_chargepoint) > -1);
      setChargepoints(cps);
      
    } catch (error) {

      console.log(error);
      
    }

  }

  useEffect(() => {
    loadChargePoints();
  }, [site, context.chargepoints.data])

  const handleEdit = () => {
    if(typeof props.handleEdit === 'function')
      props.handleEdit();
  }

  const handleDeleteSuccess = () => {
    if(typeof props.onDelete === 'function')
      props.onDelete();
  }

  const handleAddSuccess = () => {
    setShowAddChargepoint(false);
    loadChargePoints();
  }
  
  return (
    <React.Fragment>

      {site === null && <Alert severity="error" variant="outlined" style={{marginTop: 15}}>Site not found</Alert>}
      {site !== null && 
        <React.Fragment>
          <Box style={{display: 'flex', gap: 20, marginTop: 40}}>
            <Box style={{display: 'flex', gap: 20}}>
              <img src={Images.icon_location} alt={site.site_name} style={{height: 80, width: 'auto'}} />
              <Box style={{display: 'flex', flexDirection: 'column'}}>
                  <Typography variant="subtitle2" color="primary">{site.site_name}</Typography>
                  {site.chargepoints && <Typography variant="subtitle2">{`${site.chargepoints} Chargepoint${site.chargepoints > 1 ? 's' : ''}`}</Typography>}
                  {!site.chargepoints && <Typography variant="subtitle2">No Chargepoints</Typography>}
                  <Typography variant="body2" color="textSecondary">{`${site.town}, ${site.postal_code}`}</Typography>
              </Box>
            </Box>
          </Box>

          <Box style={{marginTop: 40}}>
            <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <Typography variant="h6" style={{display: 'flex', gap: 10, alignItems: 'center'}}><LocationOnIcon color="primary" />Address</Typography>
              <IconButton onClick={handleEdit} size="small"><EditIcon color="primary" /></IconButton>
            </Box>
            <Box style={{marginTop: 10}}>
              {site.address_line_1 && <Typography variant="body1">{site.address_line_1}</Typography>}
              {site.address_line_2 && <Typography variant="body1">{site.address_line_2}</Typography>}
              {site.address_line_3 && <Typography variant="body1">{site.address_line_3}</Typography>}
              {site.town && <Typography variant="body1">{site.town}</Typography>}
              {site.county && <Typography variant="body1">{site.county}</Typography>}
              {site.postal_code && <Typography variant="body1">{site.postal_code}</Typography>}
              {site.country && <Typography variant="body1">{site.country}</Typography>}
            </Box>
          </Box>

          <Box style={{marginTop: 40}}>
            <Typography variant="h6" style={{display: 'flex', gap: 10, alignItems: 'center'}}>Chargepoints</Typography>
            <Box style={{marginTop: 10}}>
              {chargepoints.length === 0 && <Typography variant="body1" color="textSecondary">No chargepoints</Typography>}
              <List dense>
                {chargepoints.map((cp, idx) => (
                  <ProjectSiteChargepoint key={idx} id_project_site={site.id_project_site} id_project={site.id_project} chargepoint={cp} />
                ))}
              </List>
              <Button variant="contained" onClick={() => setShowAddChargepoint(true)} color="primary">Add</Button>
            </Box>
          </Box>

          

          <Box style={{flex: 1}}></Box>

          <Box style={{paddingTop: 30, paddingBottom: 30}}>

              <Typography variant="h6">Options</Typography>
              <List dense>
                <ListItem button onClick={() => setShowDelete(true)}>
                  <ListItemIcon><DeleteIcon color="primary" /></ListItemIcon>
                  <ListItemText primary="Delete this site" />
                </ListItem>
              </List>

          </Box>

          <ProjectSiteDelete site={site} show={showDelete} onClose={() => setShowDelete(false)} onSuccess={handleDeleteSuccess}/>
          {showAddChargepoint && <ProjectSiteChargepointAdd site={site} show={true} onClose={() => setShowAddChargepoint(false)} onSuccess={handleAddSuccess} />}

        </React.Fragment>
      }


    </React.Fragment>
  )

}

export default ProjectSiteMore