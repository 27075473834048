import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, List, ListItem, ListItemText, Box, Divider } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, {useContext, useState} from 'react';
import ProgressButton from '../components/progressButton';
import globalContext from '../context/globalContext';
import API from '../global/api';
import LockIcon from '@material-ui/icons/Lock';
import { SQLtoUTCDate, SQLtoUTCDateObj } from '../global/dateTimeHelpers';


const ProjectAccess = props => {

  const context = useContext(globalContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [project, setProject] = useState(props.project);

  const members = [
    {
      id_user: 33,
      name: 'Kieran Rushby',
      isAdmin: true
    }
  ]


  const handleUnlock = async () => {

    try {
          setError(null);
          setLoading(true);
          const unlockProject = await API.req(`/project/unlock/${project.id_project}`, 'GET');
          if(unlockProject.success){
            context.projectUpdate(unlockProject.result);
            setProject(unlockProject.result);
          } else{
            setError(unlockProject.msg)
          }
          setLoading(false);
      
    } catch (error) {
      setLoading(false);
      setError(typeof error.msg !== 'undefined' ? error.msg : JSON.stringify(error))
    }

  }



  const handleClose = () => {
    setError(null);
    if(typeof props.onClose === 'function')
      props.onClose();
  }

  const isUnlocked = project?.unlocked_until !== null && SQLtoUTCDateObj(project?.unlocked_until) > new Date() ? true : false;


  return (
    <React.Fragment>

      <Dialog open={props.show} fullWidth maxWidth="xs" onClose={handleClose}>

        <DialogTitle><Box style={{display: 'flex', gap: 10, alignItems: 'center'}}><LockIcon color="primary" />Project Access</Box></DialogTitle>
        <DialogContent>

          <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px'}}>
            <Typography variant="subtitle2" align="center">Project Code</Typography>          
            <Typography variant="h3" align="center">{project?.project_code}</Typography>   
            {isUnlocked && <Typography variant="h6" align="center" color="error">{SQLtoUTCDate(project?.unlocked_until)}</Typography>}
            <Typography variant="body2" align="center">Grant access to people outside your team by sharing the code above and pressing unlock. This will unlock the project for 1 hour or until you press lock.</Typography>  
            <ProgressButton variant="contained" onClick={handleUnlock} color="primary" waiting={loading}>UNLOCK</ProgressButton>
          </Box>

          {error && <Alert severity="error">{error}</Alert>}
          <Divider style={{marginTop: 20}} />
          <Box style={{paddingTop: 20}}>

            <Typography variant="subtitle1">Members</Typography>
            <List dense>
              {members.map((m, idx) => (
                <ListItem>
                  <ListItemText primary={m.name} secondary={m.isAdmin ? 'Admin' : 'Member'} />
                </ListItem>
              ))}
            </List>

          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">CLOSE</Button>
        </DialogActions>


      </Dialog>

    </React.Fragment>
  )

}

export default ProjectAccess

