import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, {useState} from 'react';
import ProgressButton from '../components/progressButton';
import API from '../global/api';
import DeleteIcon from '@material-ui/icons/Delete';

const AccountDeleteRequest = props => {

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {

    try {

      setLoading(true);

      const createDeleteAccountTicket = await API.req(`/user/close`, 'POST');
      if(createDeleteAccountTicket.success){
        setLoading(false);
        setSuccess(true);
      } else {
        setLoading(false);
        setError(createDeleteAccountTicket.msg)
      }
      
    } catch (error) {
      setLoading(false);
      setError(typeof error.msg !== 'undefined' ? error.msg : JSON.stringify(error))
    }

  }

  const handleClose = () => {
    setError(null);
    if(typeof props.onClose === 'function')
      props.onClose();
  }
  

  return (
    <React.Fragment>

      <Dialog open={props.show} fullWidth maxWidth="sm" onClose={handleClose}>

        <DialogTitle><Typography variant="h5" style={{display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center'}}><DeleteIcon color="primary" style={{fontSize: 32}} />Delete my account</Typography></DialogTitle>
        
        {!success &&
          <React.Fragment>
            <DialogContent>
              {error && <Alert severity="error">{error}</Alert>}
              <Typography variant="body2">Confirm you wish for your account to be deleted. This will not remove your projects or the chargepoint data associated with your account. This action only removes your user account from Rolec EV Connect</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <ProgressButton variant="contained" onClick={handleSubmit} color="primary" waiting={loading}>CONFIRM</ProgressButton>
            </DialogActions>
          </React.Fragment>
        }

        {success &&
          <React.Fragment>
            <DialogContent>
              <Alert severity='success'>A request has been submitted to close your account. We will email you when your account has been deleted and you will no longer be able to use the Rolec EVConnect app or Rolec EVConnect + services.</Alert>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleClose} color="primary">OK</Button>
            </DialogActions>
          </React.Fragment>
        }


      </Dialog>

    </React.Fragment>
  )

}

export default AccountDeleteRequest

