import React, {useContext, useEffect, useState} from 'react';
import {Avatar, Box, makeStyles, Typography, useTheme, Link, Card, Button} from '@material-ui/core';
import PageToolbar from '../components/pageToolbar';
import globalContext from '../context/globalContext';
import LockIcon from '@material-ui/icons/Lock';
import SideSlidePanel from '../components/sideSlidePanel';
import ChangePassword from '../content/changePassword';
import ProgressButton from '../components/progressButton';
import { isValidForm, makeValidation, validateForm } from '../global/formHelper';
import InputField from '../components/inputField';
import Alert from '@material-ui/lab/Alert';
import API from '../global/api';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountDeleteRequest from '../content/accountDeleteRequest';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  link: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    marginTop: 10
  },
  cardIconed: {
    display: 'flex', 
    flexDirection: 'row', 
    gap: '20px'
  },
  cardIconedBody: {
    padding: 40, 
    flex: 1, 
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px'
  },
  avatar: {
    width: 100, 
    height: 100, 
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 42
  }
}))


const Profile = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showAccountDelete, setShowAccountDelete] = useState(false);

  const existingProfile = {
    first_name: context.user.profile.user.first_name,
    last_name: context.user.profile.user.last_name,
    company_name: context.user.profile.user.company_name,
    company_reg_number: context.user.profile.user.company_reg_number,
    position: context.user.profile.user.position,
    phone: context.user.profile.user.phone,
    approved_already: context.user.profile.user.approved_already 
  };

  const [form, setForm] = useState(JSON.parse(JSON.stringify(existingProfile)));
  const [changed, setChanged] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'first_name',
      display_name: 'First Name',
      required: true
    },
    {
      name: 'last_name',
      display_name: 'Last Name',
      required: true
    },
    {
      name: 'company_name',
      display_name: 'Company Name',
      required: true
    },
    {
      name: 'company_reg_number',
      display_name: 'Company Registration Number',
      required: false
    },
    {
      name: 'position',
      display_name: 'Position',
      required: false
    },
    {
      name: 'phone',
      display_name: 'Phone',
      required: true
    },
    {
      name: 'approved_already',
      display_name: 'Approved Rolec Installed?',
      required: false
    }
  ]))

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);

    if(JSON.stringify(form) !== JSON.stringify(existingProfile))
      setChanged(true);
    else
      setChanged(false);

  }, [form])

  const handleSubmit = async () => {

    try {

      const valid = isValidForm(form, validation);
      setValidation(valid.validation);

      if(valid.isValid){

        setError(null);
        setLoading(true);
        setSuccess(false);

        const updateProfile = await API.req(`/user`, 'PUT', form);
        setLoading(false);
        if(updateProfile.success){
          context.userValidate();
          setSuccess(true);
        } else{
          setError(updateProfile.msg)
        }
      }
        
    } catch (error) {
      setLoading(false);
      setError(typeof error.msg !== 'undefined' ? error.msg : JSON.stringify(error))
    }

  }


  return (
    <React.Fragment>
      <div className={classes.container}>

        <PageToolbar label="My Profile" />

        <Box style={{marginBottom: 40}}>
          <Card elevation={1} style={{maxWidth: 520}}>
              <Box className={classes.cardIconed}>
                <Box className={classes.cardIconedBody}>

                    <Avatar className={classes.avatar}>{context.user.profile.user.first_name.charAt(0)}</Avatar>
                    <Box>
                      <Typography variant="h4">{context.user.profile.user.first_name} {context.user.profile.user.last_name}</Typography>
                      <Typography variant="h6">{context.user.profile.user.company_name}</Typography>
                      <Link color="primary" onClick={() => setShowChangePassword(true)} className={classes.link}><LockIcon style={{fontSize: 18}} />Change Password</Link>
                    </Box>

                </Box>

              </Box>
          </Card>
        </Box>

        <Box className='mb' style={{maxWidth: 520, display: 'flex', flexDirection: 'column', gap: 10}}>
          {error && <Alert severity='error'>{error}</Alert>}
          {success && <Alert severity='success'>Your profile has been updated</Alert>}
          <InputField name='first_name' hoverLabel={true} validation={validation.first_name} value={form.first_name} handleChange={handleInputChange} />
          <InputField name='last_name' hoverLabel={true} validation={validation.last_name} value={form.last_name} handleChange={handleInputChange} />
          <InputField name='company_name' hoverLabel={true} validation={validation.company_name} value={form.company_name} handleChange={handleInputChange} />
          <InputField name='company_reg_number' hoverLabel={true} validation={validation.company_reg_number} value={form.company_reg_number} handleChange={handleInputChange} />
          <InputField name='position' hoverLabel={true} validation={validation.position} value={form.position} handleChange={handleInputChange} />
          <InputField name='phone' hoverLabel={true} validation={validation.phone} value={form.phone} handleChange={handleInputChange} />
          <InputField name='approved_already' hoverLabel={true} validation={validation.approved_already} value={form.approved_already} handleChange={handleInputChange} type='select' items={[{key: 1, value: 'Yes'},{key: 0, value: 'No'}]} />
        </Box>

        <Box style={{marginBottom: 50}}>
          <ProgressButton waiting={loading} onClick={handleSubmit} color="primary" variant="contained" disabled={!changed}>SAVE</ProgressButton>
        </Box>

        <Box className='mb'>
          <Button onClick={() => setShowAccountDelete(true)}><DeleteIcon style={{fontSize: 22}} /> DELETE MY ACCOUNT</Button>
        </Box>

      </div>

      <SideSlidePanel title="Change password" side="left" show={showChangePassword} handleClose={() => setShowChangePassword(false)}><ChangePassword handleClose={() => setShowChangePassword(null)} /></SideSlidePanel>
      <AccountDeleteRequest show={showAccountDelete} onClose={() => setShowAccountDelete(false)} />


    </React.Fragment>
  );
}

export default Profile;
