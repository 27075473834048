import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, IconButton, Divider, useTheme } from '@material-ui/core'
import InputField from '../components/inputField';
import { makeValidation, resetValidation, validateForm } from '../global/formHelper';
import ProgressButton from '../components/progressButton';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { SQLtoUTCDate} from '../global/dateTimeHelpers';
import TeamMemberInviteDelete from './teamMemberInviteDelete';


const TeamInviteItem = props => {

  const {invite, idx} = props;

  const [showDelete, setShowDelete] = useState(false);

  const handleDeleteSuccess = () => {
    setShowDelete(false);
    if(typeof props.loadInvites === 'function')
      props.loadInvites();
  }

  return (
      <React.Fragment>
        
        <ListItem style={{borderTop: idx === 0 ? 'none' : '1px solid #c4c4c4'}}>
          <ListItemText primary={invite.email} secondary={`Invited: ${SQLtoUTCDate(invite.created_on, false)}`} />
          <IconButton color="primary" onClick={() => setShowDelete(true)}><CloseIcon /></IconButton>
        </ListItem>


        <TeamMemberInviteDelete onSuccess={handleDeleteSuccess} invite={invite} show={showDelete} onClose={() => setShowDelete(false)} />
      </React.Fragment>
  )
}

const TeamMemberAdd = props => {

  const theme = useTheme();

  const {team} = props;


  const [invites, setInvites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadInvites = async () => {

    try {

        setError(null);
        setLoading(true);

        const getInvites = await API.req(`/team/${team.id_team}/invites`, 'GET');

        if(!getInvites.success)
            throw getInvites;

        setInvites(getInvites.result);
        setLoading(false);

        
    } catch (error) {
        setError('ERR_LOADING_INVITES');
        setLoading(false);
    }

  }

  useEffect(() => {
    loadInvites();
  }, [])



  const [form, setForm] = useState({
    'email': ''
  });

  const [validation, setValidation] = useState(makeValidation([
    {
    name: 'email',
    display_name: 'Email address',
    required: true
    }
  ]))

  

  const handleInputChange = e => {
    const newForm = JSON.parse(JSON.stringify(form))
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }


  const handleSubmit = async () => {

    try {

      setError(null);
      setLoading(true);
      const inviteTeamMember = await API.req(`/team/${team.id_team}/invite`, 'POST', form);
      setLoading(false);
      if(inviteTeamMember.success){
        setForm({email: ''})
        setValidation(resetValidation(validation));
        loadInvites();
      } else{
        setError(inviteTeamMember.msg)
      }
  
    } catch (error) {
      setLoading(false);
      setError(typeof error.msg !== 'undefined' ? error.msg : 'ERR_INVITING_MEMBER')
    }

  }

  useEffect(() => {

    setValidation(validateForm(form, validation))

  }, [form])
  
  return (
    <React.Fragment>

      
      {invites.length > 0 && 
        <Box>
            <Typography variant="subtitle2" style={{backgroundColor: theme.palette.grey[200], padding: 6, borderRadius: 10}}>Pending Invites</Typography>
            <List>
              {invites.map((i, idx) => (
                <TeamInviteItem invite={i} idx={idx} loadInvites={loadInvites}  />
              ))}
            </List>
        </Box>
      }

      <Box style={{paddingTop: 30}}>
        <Typography variant="h6">Add a member</Typography>
        <Typography variant="body2" style={{paddingBottom: 10}}>Enter an email address below to invite someone to this team.</Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <InputField type="text" name="email" validation={validation.email} value={form.email} handleChange={handleInputChange} />
        <Box style={{display: 'flex', justifyContent: 'flex-end'}}>
          <ProgressButton variant="contained" onClick={handleSubmit} color="primary" size="large" waiting={loading}>Invite</ProgressButton>
        </Box>
      </Box>

    </React.Fragment>
  )

}

export default TeamMemberAdd