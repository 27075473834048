import React, { useContext, useEffect, useState } from 'react';
import InputField from '../components/inputField';
import { isValidForm, makeValidation, validateForm } from '../global/formHelper';
import { Box } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import API from '../global/api';
import globalContext from '../context/globalContext';
import ProgressButton from '../components/progressButton';

const ProjectAddExisting = props => {

  const context = useContext(globalContext);
  const [form, setForm] = useState({
    'project_code': ''
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [validation, setValidation] = useState(makeValidation([
    {
    name: 'project_code',
    display_name: 'Enter a project code',
    required: true
    }
  ]))

  const handleInputChange = e => {
    const newForm = JSON.parse(JSON.stringify(form))
    newForm[e.target.name] = e.target.value.toUpperCase();
    setForm(newForm);
  }

  const handleSubmit = async () => {

    try {

      const isValid = isValidForm(form, validation);
      setValidation(isValid.validation);
      if(isValid.isValid) {

          setLoading(true);
          const createProject = await API.req('/project/link', 'POST', form);
          if(createProject.success){
            context.projectAdd(createProject.result);
            setLoading(false);
            if(typeof props.onSuccess === 'function')
              props.onSuccess();
          } else {
            throw createProject;
          }

      }
      
    } catch (error) {
      setLoading(false);
      setError(typeof error.msg !== 'undefined' ? error.msg : JSON.stringify(error))
    }

  }

  useEffect(() => {

    setValidation(validateForm(form, validation))

  }, [form])



  return (
    <Box style={{paddingTop : 30}}>
      {error && <Alert severity='error'>{error}</Alert>}
      <Box style={{display: 'flex', gap: 10}}>
        <InputField type="text" name="project_code" variant="outlined" hoverLabel={true} validation={validation.project_code} value={form.project_code} handleChange={handleInputChange} placeholder="e.g. A1D2B"/>
        <Box style={{marginTop: 14}} >
          <ProgressButton variant="contained" onClick={handleSubmit} color="primary" size="large" waiting={loading}>Add</ProgressButton>
        </Box>
      </Box>
    </Box>
  )

}

export default ProjectAddExisting