import Api from '../global/api';

export function teamsLoad () {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'TEAMS_LOAD',
            status: 'LOADING',
            payload: null
        })

        Api.req(`/teams`, 'GET').then(
            res => {

                dispatch({
                    type: 'TEAMS_LOAD',
                    status: 'OK',
                    payload: res.result
                })
            },
            err => {
                // Login error
                dispatch({
                    type: 'TEAMS_LOAD',
                    status: 'error',
                    payload: typeof err.msg === 'string' ? err.msg : 'Sorry we were unable to load your teams'
                })
            }

        )

    }
}

export function teamAdd (payload) {
    return dispatch => {

        // Loading
        dispatch({
            type: 'TEAM_ADD',
            status: 'OK',
            payload: payload
        })

        return 'success';

    }
}

export function teamUpdate (payload) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'TEAM_UPDATE',
            status: 'OK',
            payload: payload
        })
        
    }
}

export function teamRemove (payload) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'TEAM_REMOVE',
            status: 'OK',
            payload: payload
        })
        
    }
}