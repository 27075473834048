import React, {useEffect, useState} from 'react';
import './styles.css';
import { makeStyles, useTheme, Typography, Button, Collapse, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeValidation, validateForm, isValidForm } from '../../global/formHelper';

import InputField from '../../components/inputField';
import ProgressButton from '../../components/progressButton';

import { useParams } from 'react-router';

import API from '../../global/api';
import InstallerEndUserLicenceAgreement from './acceptLicence';

const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: 10
  },
  footer: {
    marginTop: 40,
    textAlign: 'center'
  },
}))


const Register = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const {token} = useParams();

  const [step, setStep] = useState(0);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const [form, setForm] = useState({
    username: '',
    email: '',
    password: '',
    confirm_password: '',
    first_name: '',
    last_name: '',
    company_name: '',
    company_reg_number: '',
    position: '',
    phone: '',
    approved_already: false,
    marketing_email: false,
    marketing_phone: false,
    token: token
  })

  const [validationOne, setValidationOne] = useState(makeValidation([
    {
      name: 'first_name',
      display_name: 'First Name',
      required: true
    },
    {
      name: 'last_name',
      display_name: 'Last Name',
      required: true
    },
    {
      name: 'company_name',
      display_name: 'Company Name',
      required: true
    },
    {
      name: 'company_reg_number',
      display_name: 'Company Registration Number',
      required: false
    },
    {
      name: 'position',
      display_name: 'Position',
      required: false
    }
  ]));

  const [validationTwo, setValidationTwo] = useState(makeValidation([
    {
      name: 'email',
      display_name: 'Email Address',
      required: true
    },
    {
      name: 'phone',
      display_name: 'Phone',
      required: true
    },
    {
      name: 'approved_already',
      display_name: 'Approved Rolec Installed?',
      required: false
    }
  ]));

  const [validationThree, setValidationThree] = useState(makeValidation([
    {
      name: 'username',
      display_name: 'Username',
      required: true
    },
    {
      name: 'password',
      display_name: 'Password',
      required: true,
      additional: [
        {
          type: 'password',
          error: 'Password must be at least 8 characters and include letters and numbers'
        }
      ]
    },
    {
      name: 'confirm_password',
      display_name: 'Confirm Password',
      required: true,
      additional: [
        {
          type: 'match',
          field: 'password',
          error: 'Your passwords do not match'
        }
      ]
    },
    {
      name: 'marketing_email',
      display_name: 'Marketing Email',
      required: false
    },
    {
      name: 'marketing_phone',
      display_name: 'Marketing Phone',
      required: false
    },
  ]));

  useEffect(() => {
    const newValidationOne = validateForm(form, validationOne);
    const newValidationTwo = validateForm(form, validationTwo);
    const newValidationThree = validateForm(form, validationThree);
    setValidationOne(newValidationOne);
    setValidationTwo(newValidationTwo);
    setValidationThree(newValidationThree);
  }, [form])

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleCheckBoxChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.checked;
    setForm(newForm);
  }

  const handleSubmit = () => {
    const validOne = isValidForm(form, validationOne);
    const validTwo = isValidForm(form, validationTwo);
    const validThree = isValidForm(form, validationThree);
    setValidationOne(validOne.validation);
    setValidationTwo(validTwo.validation);
    setValidationThree(validThree.validation);


    if(validOne.isValid && validTwo.isValid && validThree.isValid)
      props.handleRegister(form);
  }

  const handleConfirmTerms = () => {

    const validOne = isValidForm(form, validationOne);
    const validTwo = isValidForm(form, validationTwo);
    const validThree = isValidForm(form, validationThree);
    setValidationOne(validOne.validation);
    setValidationTwo(validTwo.validation);
    setValidationThree(validThree.validation);

    if(validOne.isValid && validTwo.isValid && validThree.isValid)
      setAcceptTerms(true);

  }

  const handleAcceptTerms = () => {
    setAcceptTerms(false);
    handleSubmit();
  }

  const stepUp = () => {
    switch(step) {
      case 0:
        goStepOne();
        break;
      case 1:
        goStepTwo();
        break;
      default:
        //Nothing
    }
  }

  const goStepOne = () => {
    const validOne = isValidForm(form, validationOne);
    setValidationOne(validOne.validation);

    if(validOne.isValid)
      setStep(1);
  }

  const goStepTwo = () => {
    const validTwo = isValidForm(form, validationTwo);
    setValidationTwo(validTwo.validation);

    if(validTwo.isValid)
      setStep(2);
  }

  const stepDown = () => {
    if(step > 0)
      setStep(step-1);
  }


  return (
    <div>

      <div className={classes.form}>
        <Collapse in={step === 0}>
          <InputField name='first_name' hoverLabel={true} validation={validationOne.first_name} value={form.first_name} handleChange={handleInputChange} />
          <InputField name='last_name' hoverLabel={true} validation={validationOne.last_name} value={form.last_name} handleChange={handleInputChange} />
          <InputField name='company_name' hoverLabel={true} validation={validationOne.company_name} value={form.company_name} handleChange={handleInputChange} />
          <InputField name='company_reg_number' hoverLabel={true} validation={validationOne.company_reg_number} value={form.company_reg_number} handleChange={handleInputChange} />
          <InputField name='position' hoverLabel={true} validation={validationOne.position} value={form.position} handleChange={handleInputChange} />
        </Collapse>

        <Collapse in={step === 1}>
          <InputField name='email' hoverLabel={true} validation={validationTwo.email} value={form.email} handleChange={handleInputChange} />
          <InputField name='phone' hoverLabel={true} validation={validationTwo.phone} value={form.phone} handleChange={handleInputChange} />

          <InputField name='approved_already' hoverLabel={true} validation={validationTwo.approved_already} value={form.approved_already} handleChange={handleInputChange} type='select' items={[{key: 1, value: 'Yes'},{key: 0, value: 'No'}]} />
        </Collapse>

        <Collapse in={step === 2}>
          <InputField name='username' hoverLabel={true} validation={validationThree.username} value={form.username} handleChange={handleInputChange} />
          <InputField name='password' hoverLabel={true} type='password' validation={validationThree.password} value={form.password} handleChange={handleInputChange} />
          <InputField name='confirm_password' hoverLabel={true} type='password' validation={validationThree.confirm_password} value={form.confirm_password} handleChange={handleInputChange} />
          
          <Typography variant="caption" component="div">I confirm I wish to be kept informed of information, offers and product updates relevant to me via my selection below. I understand I can change my preferences at any time.</Typography>
          <FormControlLabel control={<Checkbox name='marketing_email' value={form.marketing_email} onChange={handleCheckBoxChange} />} label="Email" />
          <FormControlLabel control={<Checkbox name='marketing_phone' value={form.marketing_phone} onChange={handleCheckBoxChange} />} label="Phone" />
        </Collapse>
        
      </div>
      
      <div className={classes.footer}>
        <Box className="flex-row-end" style={{marginBottom: 20}}>
          {step > 0 && <Button variant="contained" size="large" onClick={stepDown}>Back</Button>}
          <Box className="flex"></Box>
          {step < 2 && <Button variant="contained" size="large" color="primary" onClick={stepUp}>Next</Button>}
          {step === 2 && <ProgressButton variant="contained" size="large" color="primary" fullWidth waiting={props.user.status === 'loading'} onClick={handleConfirmTerms}>Create Account</ProgressButton>}
        </Box>
        <Button onClick={props.handleBack}>Back to sign in</Button>
      </div>

      <InstallerEndUserLicenceAgreement show={acceptTerms} onRefuse={() => setAcceptTerms(false)} onAccept={handleAcceptTerms} onClose={() => setAcceptTerms(false)} />

    </div>
  );
}

export default Register;
