import React, {useEffect, useState} from 'react';

import {Typography,  ListItem, ListItemText, ListItemIcon, CircularProgress, IconButton, Link, Box} from '@material-ui/core';
import {SQLtoUTCDate} from '../global/dateTimeHelpers';

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import API from '../global/api';
import Check from '@material-ui/icons/Check';

const ChargepointReport = props => {

    var timeout;
    const [report, setReport] = useState(props.report);
    const [downloading, setDownloading] = useState(false);
    

    const handleDownload = (e) => {
        
        e.preventDefault()
        e.stopPropagation()

        if(report.completed_on !== null) {
            setDownloading(report.id_chargepoint_report);

            API.fileDownload(`/chargepoint/${report.id_chargepoint}/report/${report.id_chargepoint_report}/download`, report.report_filename).then(
                res => {
                    setDownloading(null);
                    console.log(res);
                },
                err => {
                    setDownloading(null);
                    console.log(err);
                }
            )
        }
    }

    const reloadReport = async () => {

        try {

            const getReport = await API.req(`/chargepoint/${report.id_chargepoint}/report/${report.id_chargepoint_report}`, 'GET');
            if(getReport.success)
                setReport(getReport.result);
                
        } catch (error) {
            
        }

    }

    useEffect(() => {

        if(report.completed_on === null)
            timeout = setTimeout(reloadReport, 7000);

        return () => {
            if(typeof timeout !== 'undefined')
                clearTimeout(timeout);
        }

    }, [report])

    return (
        <React.Fragment>
            <ListItem>
                <ListItemIcon>
                    {downloading !== report.id_chargepoint_report && <IconButton onClick={(e) => handleDownload(e, report)}><InsertDriveFileIcon /></IconButton>}
                    {downloading === report.id_chargepoint_report && <CircularProgress size={24} />}
                </ListItemIcon>
                <ListItemText
                    primary={<Link href="#" color={report.completed_on !== null ? 'primary' : 'textSecondary'} onClick={(e) => handleDownload(e, report)}>{report.report_filename} ({report.report_size !== null ? report.report_size : 'processing'})</Link>}
                    secondary={<Typography color="textSecondary" variant="caption">{SQLtoUTCDate(report.requested_on, true)}</Typography>}
                />
                <Box>
                    {report.completed_on !== null && <Check color="primary" />}
                    {report.completed_on === null && <CircularProgress size={18} />}
                </Box>
            </ListItem>
        </React.Fragment>
    )
}
export default ChargepointReport;