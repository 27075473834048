import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, {useContext, useState} from 'react';
import ProgressButton from '../components/progressButton';
import globalContext from '../context/globalContext';
import API from '../global/api';


const TeamDelete = props => {

  const context = useContext(globalContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const team = props.team;


  const handleSubmit = async () => {

    try {
          setError(null);
          setLoading(true);
          const deleteTeam = await API.req(`/team/${team.id_team}`, 'DELETE');
          setLoading(false);
          if(deleteTeam.success){
            context.teamRemove(team);
          } else{
            setError(deleteTeam.msg)
          }
      
    } catch (error) {
      setLoading(false);
      setError(typeof error.msg !== 'undefined' ? error.msg : JSON.stringify(error))
    }

  }

  

  const handleClose = () => {
    setError(null);
    if(typeof props.onClose === 'function')
      props.onClose();
  }


  return (
    <React.Fragment>

      <Dialog open={props.show} fullWidth maxWidth="xs" onClose={handleClose}>

        <DialogTitle>Delete Team <b>{team?.team_name}</b></DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <Typography variant="body1">Confirm you wish to delete team "{team?.team_name}". Once complete, this action is unrecoverable.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ProgressButton variant="contained" onClick={handleSubmit} color="primary" waiting={loading}>CONFIRM</ProgressButton>
        </DialogActions>


      </Dialog>

    </React.Fragment>
  )

}

export default TeamDelete

