import { Dialog, DialogContent, Button, Box, Typography, Link } from '@material-ui/core';
import { Alert} from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import API from '../global/api';


const PrivacyNotice = props => {

    const [hasRefused, setHasRefused] = useState(false);
    const [show, setShow] = useState(false);

    const handleAgree = () => {
        API.setLocal('privacyAccepted', new Date().toISOString());
        setShow(false);
    }

    const handleDisagree = () => {
        setHasRefused(true);
    }

    const checkPrivacy = async () => {
        const privacy_accepted = await API.getLocal('privacyAccepted');
        console.log('privacy check');
        console.log(privacy_accepted);
        if(typeof privacy_accepted === 'undefined' || privacy_accepted === null)
        setShow(true);
    }

    useEffect(() =>{
      checkPrivacy();
    }, [])

    return (
        <React.Fragment>

        <Dialog open={show} fullWidth maxWidth="xs">

            <DialogContent>
                <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '6px'}}>
                    <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PrivacyTipIcon" style={{width: 58}}><path d="M12 1 3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-1 6h2v2h-2V7zm0 4h2v6h-2v-6z"></path></svg>
                    <Typography variant="h6" style={{marginBottom: 15}}>Let's talk about privacy.</Typography>
                </Box>
                {hasRefused && <Alert severity='error' style={{marginBottom: 15}}>Unfortunatly if you do not agree to our privacy policy you are unable to use this application. Please close this tab.</Alert>}
                <Typography variant="body1" style={{marginBottom: 10}}>Before use of this application, please indicate your consent to our processing of your personal data (including your name, contact details, financial (in each case to the extent that you choose to supply them) and device information) as described in our privacy policy.</Typography>
                <Typography variant="body1" style={{marginBottom: 10}}>You can find a link to our policy below, please ensure you have read and understood the policy before continuing to use this app.</Typography>
                <Link href="https://www.rolecserv.com/rolec-smart-solutions/evconnect-privacy-policy" style={{marginBottom: 15}} color="primary">Read the privacy policy here</Link>
                <Box style={{display: 'flex', flex: 1, justifyContent: 'space-between', paddingTop: 25, alignItems: 'center'}}>
                    <Typography variant="subtitle2">I accept the privacy policy and wish to proceed.</Typography>
                    <Box style={{display: 'flex', justifyContent: 'flex-end', gap: '20px'}}>
                        <Button onClick={handleDisagree}>No</Button>
                        <Button variant="contained" onClick={handleAgree} color="primary">YES</Button>
                    </Box>
                </Box>
            </DialogContent>

        </Dialog>

        </React.Fragment>
    )

}

export default PrivacyNotice
