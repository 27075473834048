import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, {useContext, useState} from 'react';
import ProgressButton from '../components/progressButton';
import globalContext from '../context/globalContext';
import API from '../global/api';


const TeamMemberDelete = props => {

  const context = useContext(globalContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const member = props.member;



  const handleSubmit = async () => {

    try {
          setError(null);
          setLoading(true);
          const deleteTeamMember = await API.req(`/team/${member.id_team}/member/${member.id_user}`, 'DELETE');
          setLoading(false);
          if(deleteTeamMember.success){
            if(typeof props.onSuccess === 'function')
              props.onSuccess();
          } else{
            setError(deleteTeamMember.msg)
          }
      
    } catch (error) {
      setLoading(false);
      setError(typeof error.msg !== 'undefined' ? error.msg : JSON.stringify(error))
    }

  }

  

  const handleClose = () => {
    setError(null);
    if(typeof props.onClose === 'function')
      props.onClose();
  }


  return (
    <React.Fragment>

      <Dialog open={props.show} fullWidth maxWidth="xs" onClose={handleClose}>

        <DialogTitle>Delete Team Member <b>{member?.name}</b></DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <Typography variant="body1">Confirm you wish to delete team member "{member?.name}". This will also remove this member from all projects associated to this team.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ProgressButton variant="contained" onClick={handleSubmit} color="primary" waiting={loading}>CONFIRM</ProgressButton>
        </DialogActions>


      </Dialog>

    </React.Fragment>
  )

}

export default TeamMemberDelete

