import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, {useContext, useState} from 'react';
import { useHistory } from 'react-router-dom';
import ProgressButton from '../components/progressButton';
import globalContext from '../context/globalContext';
import API from '../global/api';


const TeamLeave = props => {

  const context = useContext(globalContext);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const team = props.team;


  const handleSubmit = async () => {

    try {
          setError(null);
          setLoading(true);
          const leaveTeam = await API.req(`/team/${team.id_team}/leave`, 'DELETE');
          setLoading(false);
          if(leaveTeam.success){
            if(typeof props.onSuccess === 'function')
              props.onSuccess();
          } else{
            setError(leaveTeam.msg)
          }
      
    } catch (error) {
      setLoading(false);
      setError(typeof error.msg !== 'undefined' ? error.msg : JSON.stringify(error))
    }

  }

  const handleClose = () => {
    setError(null);
    if(typeof props.onClose === 'function')
      props.onClose();
  }


  return (
    <React.Fragment>

      <Dialog open={props.show} fullWidth maxWidth="xs" onClose={handleClose}>

        <DialogTitle>Leave Team <b>{team?.team_name}</b></DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <Typography variant="body1">Confirm you wish to leave the team "{team?.team_name}". Leaving a team will also remove your access to any projects allocated to this team.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ProgressButton variant="contained" onClick={handleSubmit} color="primary" waiting={loading}>CONFIRM</ProgressButton>
        </DialogActions>


      </Dialog>

    </React.Fragment>
  )

}

export default TeamLeave

