import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import user from "../reducers/userReducer";
import project from "../reducers/projectReducer";
import teams from "../reducers/teamsReducer";
import chargepoints from "../reducers/chargePointsReducer";

export default createStore(
    combineReducers({
        user,
        project,
        chargepoints,
        teams
    }),
    {},
    applyMiddleware(thunk)
)