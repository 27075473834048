import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import store from './global/store';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';

const palette = {
  type: 'light',
  primary: {
    main: '#74AF28',
    contrastText: '#fff'
  },
  success: {
    main: '#6CB33F',
    contrastText: '#fff',
    light: '#A9FF73',
    dark: '#5C9936',
    lighter: '#E7FFD9',
  },
  error: {
    main: '#FF0000',
    contrastText: '#fff'
  },
  text: {
    primary: '#3C3C3B',
    contrastText: '#fff'
  },
  background: {
    default: '#ffffff'
  },
  vacant: {
    main: '#003C73',
    contrastText: '#fff'
  },
  assigned: {
    main: '#6cb33f',
    contrastText: '#fff'
  },
  reserved: {
    main: '#ecab00',
    contrastText: '#fff'
  },
  fault: {
    main: '#ff0000',
    contrastText: '#fff'
  }
};

const theme = createTheme({
  palette: palette,
  typography: {
    fontSize: 14,
    fontWeightRegular: 500,
    fontFamily: [
      'Montserrat', 
      'Roboto', 
      'Oxygen',
      'Ubuntu', 
      'Cantarell', 
      'Fira Sans', 
      'Droid Sans', 
      'Helvetica Neue'
    ].join(','),
    h1: {
      fontSize: '3.2rem'
    },
    h2: {
      fontSize: '2.6rem'
    },
    h3: {
      fontSize: '2.2rem',
      fontWeight: 500
    },
    h4: {
      fontSize: '1.6rem',
      fontWeight: 500
    },
    h5: {
      fontSize: '1.4rem',
      fontWeight: 500
    },
    h6: {
      fontSize: '1.1rem',
      fontWeight: 500
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderTopRightRadius: 26,
        borderTopLeftRadius: 26,
        borderBottomRightRadius: 26,
        borderBottomLeftRadius: 26
      },
      label: {
        textTransform: 'none'
      },
      containedSizeLarge: {
        padding: '12px 32px',
        fontSize: '1rem'
      },
      contained: {
        backgroundColor: palette.text.primary,
        color: palette.text.contrastText,
        padding: '6px 22px',
        '&:hover': {
          backgroundColor: '#787878',
          color: palette.text.contrastText,
        },
      }
    },
    MuiInputBase: {
      root: {
        borderBottomColor: 'transparent'
      }
    },
    MuiFilledInput: {
      root: {
        borderTopRightRadius: 32,
        borderTopLeftRadius: 32,
        borderBottomRightRadius: 32,
        borderBottomLeftRadius: 32
      },
      input: {
        padding: '27px 22px 10px'
      }
    },
    MuiOutlinedInput: {
      root: {
        borderTopRightRadius: 32,
        borderTopLeftRadius: 32,
        borderBottomRightRadius: 32,
        borderBottomLeftRadius: 32
      },
      input: {
        padding: '27px 22px 10px'
      },
      notchedOutline: {
        borderColor: palette.primary.main
      }
    },
    MuiInputLabel: {
      filled: {
        transform: 'translate(18px, 20px) scale(1)',
        '&$shrink': {
          transform: 'translate(22px, 10px) scale(0.75)'
        }
      }
    },
    MuiSlider: {
      thumb: {
        color: palette.primary.contrastText,
        border: `2px solid ${palette.primary.main}`
      }
    },
    MuiTableCell: {
      head: {
        backgroundColor: palette.text.primary,
        color: palette.primary.contrastText
      }
    },
    MuiTableSortLabel: {
      root: {
        color: palette.primary.contrastText,
        '&$hover': {
          color: palette.primary.contrastText,
        },
        '&$active': {
          color: palette.primary.contrastText,
          '&& $icon': {
            color: palette.primary.contrastText,
          },
        },
        '&& $icon': {
          color: palette.primary.contrastText,
        },
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 40
      }
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: '#f1f1f1'
      }
    },
    MuiCard: {
      root: {
        borderRadius: 14
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: 14
      }
    }
  },
  props: {
    MuiFilledInput: {
      disableUnderline: true
    },
    MuiLink: {
      color: 'textPrimary'
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
