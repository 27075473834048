import React, {useState, useEffect} from 'react';
import { Typography, List, CircularProgress, Box} from '@material-ui/core';
import ChargePointPhotoUpload from './chargepointPhotoUpload';
import API from '../global/api';
import ChargepointPhoto from './chargepointPhoto';
import { Alert } from '@material-ui/lab';

const ChargepointPhotos = props => {

  const {chargepoint} = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [photos, setPhotos] = useState([]);

  

  const loadPhotos = async () => {

    try {

        setError(null);
        setLoading(true);

        const getPhotos = await API.req(`/chargepoint/${chargepoint.id_chargepoint}/attach`, 'GET');

        if(!getPhotos.success)
            throw getPhotos;

        setPhotos(getPhotos.result);

        setLoading(false);

        
    } catch (error) {
        setError('ERR_LOADING_PHOTOS');
    }

  }
  
  useEffect(() =>{
    loadPhotos()
  }, [])

  return (
    <React.Fragment>
      <ChargePointPhotoUpload chargepoint={chargepoint} onUpload={loadPhotos} />
      {error && <Alert severity='error'>{error}</Alert>}
      {loading && <Box style={{display: 'flex', justifyContent: 'center', padding: 10}}><CircularProgress size={24} /></Box>}
      {(!loading && photos.length === 0) && <Typography variant="body2" color="textSecondary">No photos</Typography> } 

      {(!loading && photos.length > 0) && 
        <List dense={true}>
            {photos.map((a, idx) => (
                <ChargepointPhoto attachment={a} key={idx} onDelete={loadPhotos} />
            ))}
        </List>
      }

    </React.Fragment>
  );
}

export default ChargepointPhotos