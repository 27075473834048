import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, {useContext, useState} from 'react';
import ProgressButton from '../components/progressButton';
import globalContext from '../context/globalContext';
import API from '../global/api';


const ProjectSiteChargepointDelete = props => {

  const context = useContext(globalContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const {id_project, id_project_site, id_chargepoint} = props;

  const handleSubmit = async () => {

    try {
      
          setError(null);
          setLoading(true);
          const removeChargepoint = await API.req(`/project/${id_project}/site/${id_project_site}/chargepoint/${id_chargepoint}`, 'DELETE');
          setLoading(false);
          if(removeChargepoint.success){
            
            // Update the locally stored charge point to reflect
            const localCP = context.chargepoints.data.find(cp => cp.id_chargepoint === id_chargepoint);
            if(typeof localCP !== 'undefined')
              context.chargepointUpdate({
                ...localCP,
                id_project_site: null
              })

            if(typeof props.onSuccess === 'function')
              props.onSuccess();
          } else{
            setError(removeChargepoint.msg)
          }
      
    } catch (error) {
      setLoading(false);
      setError(typeof error.msg !== 'undefined' ? error.msg : JSON.stringify(error))
    }

  }

  const handleClose = () => {
    setError(null);
    if(typeof props.onClose === 'function')
      props.onClose();
  }


  return (
    <React.Fragment>

      <Dialog open={props.show} fullWidth maxWidth="xs" onClose={handleClose}>

        <DialogTitle>Remove chargepoint from this site</DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <Typography variant="body1">Confirm you wish to remove the chargepoint from this site?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ProgressButton variant="contained" onClick={handleSubmit} color="primary" waiting={loading}>CONFIRM</ProgressButton>
        </DialogActions>

      </Dialog>

    </React.Fragment>
  )

}

export default ProjectSiteChargepointDelete

