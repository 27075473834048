import { ListItem, ListItemText } from "@material-ui/core";
import React, {useContext} from "react";
import globalContext from '../context/globalContext';
import { useHistory } from 'react-router-dom';


const SearchResultProject = props => {

  const {result} = props;

  const context = useContext(globalContext);
  const history = useHistory();

  const handleClick = () => {
    context.projectAdd(result);
    history.push(`/project/${result.project_code}`)
  }

  return (
    <React.Fragment>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={`${result.project_code} - ${result.project_name}`} secondary={`${new Date(result.created_on).toLocaleDateString('en-GB')} - ${result.project_status}`} />
      </ListItem>
    </React.Fragment>
  )
}

export default SearchResultProject;