import React, {useState, useEffect} from 'react';
import { Typography, List, CircularProgress, Box, Button} from '@material-ui/core';
import API from '../global/api';
import ChargepointReport from './chargepointReport';
import ChargepointReportRequest from './chargepointReportRequest';
import  VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Alert } from '@material-ui/lab';

const ChargepointReports = props => {

  const {chargepoint} = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reports, setReports] = useState([]);
  const [showReportRequest, setShowReportRequest] = useState(false);

  

  const loadReports = async () => {

    try {

        setError(null);
        setLoading(true);

        const getReports = await API.req(`/chargepoint/${chargepoint.id_chargepoint}/reports`, 'GET');

        if(!getReports.success)
            throw getReports;

        setReports(getReports.result);

        setLoading(false);

        
    } catch (error) {
        setError('ERR_LOADING_REPORTS');
    }

  }
  
  useEffect(() =>{
    loadReports()
  }, [])

  return (
    <React.Fragment>
      
      <Box style={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography variant="h6">Test Report</Typography>
        {['COMMISSIONED', 'TESTED'].indexOf(chargepoint.status) > -1 && <Button onClick={() => setShowReportRequest(true)} color="primary" size="small"><VerifiedUserIcon style={{marginRight: 5, fontSize: 18}} />GET REPORT</Button>}
      </Box>


      {loading && <Box style={{display: 'flex', justifyContent: 'center', padding: 10}}><CircularProgress size={24} /></Box>}
      {error && <Alert severity="error">{error}</Alert> } 
      {(!loading && reports.length === 0) && <Typography variant="body2" color="textSecondary">No reports</Typography> } 

      {(!loading && reports.length > 0) && 
        <List dense={true}>
            {reports.map((r, idx) => (
                <ChargepointReport report={r} key={idx} />
            ))}
        </List>
      }

      {showReportRequest && <ChargepointReportRequest show={true} chargepoint={chargepoint} onSuccess={loadReports} onClose={() => setShowReportRequest(false)} />}

    </React.Fragment>
  );
}

export default ChargepointReports