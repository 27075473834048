import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, {useState, useEffect, useContext} from 'react';
import InputField from '../components/inputField';
import ProgressButton from '../components/progressButton';
import API from '../global/api';
import { makeValidation } from '../global/formHelper';
import globalContext from '../context/globalContext';


const ProjectSiteChargepointAdd = props => {

  const context = useContext(globalContext);

  const {site} = props;

  const [chargepoint, setChargepoint] = useState(null);
  const [availableChargepoints, setAvailableChargepoints] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'chargepoint',
      display_name: 'Chargepoint',
      required: true
    }
  ]))

  const handleSubmit = async () => {

    try {
      
      setError(null);
      setLoading(true);
      const addChargepoint = await API.req(`/project/${site.id_project}/site/${site.id_project_site}/chargepoint/${chargepoint}`, 'POST');
      setLoading(false);
      if(addChargepoint.success){

        // Update the locally stored charge point to reflect
        const localCP = context.chargepoints.data.find(cp => cp.id_chargepoint === chargepoint);
        if(typeof localCP !== 'undefined')
          context.chargepointUpdate({
            ...localCP,
            id_project_site: site.id_project_site
          })

        if(typeof props.onSuccess === 'function')
          props.onSuccess();
      } else{
        setError(addChargepoint.msg)
      }
  
    } catch (error) {
      setLoading(false);
      setError(typeof error.msg !== 'undefined' ? error.msg : JSON.stringify(error))
    }

  }


  const loadChargePoints = async () => {

    let newAvailableChargepoints = [];
    const getAvailableChargepoints = context.chargepoints.data.filter(cp => cp.id_project === site.id_project && cp.id_project_site === null);
    getAvailableChargepoints.map(cp => newAvailableChargepoints.push({
      key: cp.id_chargepoint,
      value: cp.cpid
    }))
    setAvailableChargepoints(newAvailableChargepoints);

  }
  
  useEffect(() =>{
    loadChargePoints()
  }, [site])



  const handleClose = () => {
    if(typeof props.onClose === 'function')
      props.onClose()
  }
  

  return (
    <React.Fragment>

      <Dialog open={props.show} fullWidth maxWidth="xs" onClose={handleClose}>

        <DialogTitle>Add chargepoint to this site</DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <Box style={{marginBottom: 20, marginTop: 20}}>
            <InputField type="select" hoverLabel={true} name="chargepoint" validation={validation.chargepoint} value={chargepoint} handleChange={e => setChargepoint(e.target.value)} items={availableChargepoints} />  
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ProgressButton variant="contained" onClick={handleSubmit} color="primary" waiting={loading}>ADD</ProgressButton>
        </DialogActions>

      </Dialog>

    </React.Fragment>
  )

}

export default ProjectSiteChargepointAdd

