import React, { useState } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, IconButton } from '@material-ui/core'
import LinkOffIcon from '@material-ui/icons/LinkOff';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import LockIcon from '@material-ui/icons/Lock'
import ProjectDelete from './projectDelete';
import ProjectUnlink from './projectUnlink';
import ProjectNotes from './projectNotes';
import ProjectEdit from './projectEdit';
import ProjectTicket from './projectTicket';
import ProjectAccess from './projectAccess';

const ProjectMore = props => {

  const {project} = props;

  const [showDelete, setShowDelete] = useState(false);
  const [showUnlink, setShowUnlink] = useState(false);
  const [showAccess, setShowAccess] = useState(false);
  const [showTicket, setShowTicket] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  
  return (
    <React.Fragment>

      <Box className='flex-row-start'>        
        <Typography variant="subtitle1">Status: {project.project_status}</Typography>
        <IconButton href='#' onClick={() => setShowEdit(true)} color="primary" size="small"><EditIcon fontSize='small' /></IconButton>
      </Box>

      <Box style={{paddingTop: 30}}>
        <ProjectNotes id_project={project.id_project} />
      </Box>

      <Box style={{flex: 1}}></Box>

      <Box style={{paddingTop: 30, paddingBottom: 30}}>
    
          <Typography variant="h6">Options</Typography>
          <List dense>
            {/* <ListItem button onClick={() => {}}>
              <ListItemIcon><VerifiedUserIcon color="primary" /></ListItemIcon>
              <ListItemText primary="Generate test report" />
            </ListItem> */}
            <ListItem button onClick={() => setShowTicket(true)}>
              <ListItemIcon><ContactSupportIcon color="primary" /></ListItemIcon>
              <ListItemText primary="Raise a support request" />
            </ListItem>
            <ListItem button onClick={() => setShowAccess(true)}>
              <ListItemIcon><LockIcon color="primary" /></ListItemIcon>
              <ListItemText primary="Project access" />
            </ListItem>
            <ListItem button onClick={() => setShowUnlink(true)}>
              <ListItemIcon><LinkOffIcon color="primary" /></ListItemIcon>
              <ListItemText primary="Unlink me from this project" />
            </ListItem>
            <ListItem button onClick={() => setShowDelete(true)}>
              <ListItemIcon><DeleteIcon color="primary" /></ListItemIcon>
              <ListItemText primary="Delete this project" />
            </ListItem>
          </List>

      </Box>

      <ProjectDelete project={project} show={showDelete} onClose={() => setShowDelete(false)} />
      <ProjectUnlink project={project} show={showUnlink} onClose={() => setShowUnlink(false)} />
      <ProjectAccess project={project} show={showAccess} onClose={() => setShowAccess(false)} />
      <ProjectTicket project={project} show={showTicket} onClose={() => setShowTicket(false)} onSuccess={() => setShowTicket(false)} />
      <ProjectEdit project={project} show={showEdit} onClose={() => setShowEdit(false)} />

    </React.Fragment>
  )

}

export default ProjectMore