import React, { useContext, useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, IconButton, List, ListItem, Typography} from '@material-ui/core'
import globalContext from '../context/globalContext';
import Alert from '@material-ui/lab/Alert';
import Zura from '../components/zura';
import { SQLtoUTCDate } from '../global/dateTimeHelpers';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import ChargepointPhotos from './chargepointPhotos';
import ChargepointReports from './chargepointReports';
import ChargepointIcon from '../components/chargepointIcon';

const ChargepointMore = props => {

  const context = useContext(globalContext);
  const {id_chargepoint} = props;

  const [chargepoint, setChargepoint] = useState(null);
  const [configurationLog, setConfigurationLog] = useState(false);
  const [testLog, setTestLog] = useState(false);

  const loadChargePoint = () => {
    if(!id_chargepoint)
      setChargepoint(null);
    else {

      const findChargepoint = context.chargepoints.data.find(cp => cp.id_chargepoint === id_chargepoint);
      if(typeof findChargepoint !== 'undefined')
        setChargepoint(findChargepoint);
      else
        setChargepoint(null);

    }
  }

  useEffect(() => {

    loadChargePoint();

  }, [id_chargepoint])
  
  return (
    <React.Fragment>

      {chargepoint === null && <Alert severity="error" variant="outlined" style={{marginTop: 15}}>Chargepoint not found</Alert>}
      {chargepoint !== null && 
        <React.Fragment>
          <Box style={{display: 'flex', gap: 20, marginTop: 40}}>
            <ChargepointIcon type={chargepoint.unit_type_code} />
            <Box style={{display: 'flex', flexDirection: 'column'}}>
                <Typography variant="subtitle2" color="primary">{chargepoint.cpid}</Typography>
                <Typography variant="caption" component="div">Added: {SQLtoUTCDate(chargepoint.created_on, false)}</Typography>
                <Typography variant="caption" component="div">Status: {chargepoint.status}</Typography>
            </Box>
          </Box>

          <Box style={{marginTop: 40}}>
            <Typography variant="h6">Configuration logs</Typography>
            <List dense style={{width: '100%'}}>
              {chargepoint.configurations.map((config, idx) => (
                <ListItem button key={idx} onClick={() => setConfigurationLog(config)}>
                  <Box style={{display: 'flex', gap: 15, justifyContent: 'space-between', flex: 1}}>
                    <Box style={{display: 'flex', gap: 25}}>
                      <Typography variant="body2" style={{width: 60}}>{config.id_user === null ? 'Owner' : 'Installer'}</Typography>
                      <Typography variant="body2" color="textSecondary">{SQLtoUTCDate(config.timestamp, true)}</Typography>
                    </Box>
                    {idx === 0 && <Typography variant="caption" color="textSecondary">Last known</Typography>}
                    <IconButton size="small" color="primary">
                      <InfoIcon />
                    </IconButton>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>

          <Box style={{marginTop: 40}}>
            <Typography variant="h6">Test logs</Typography>
            <List dense style={{width: '100%'}}>
              {chargepoint.tests.map((connector, idx) => (
                <ListItem button key={idx} onClick={() => setTestLog(connector)}>
                  <Box style={{display: 'flex', gap: 15, justifyContent: 'space-between', flex: 1}}>
                    <Box style={{display: 'flex', gap: 25}}>
                      <Typography variant="body2" style={{width: 100}}>Connector {connector.socket}</Typography>
                      {connector.results[0] &&
                        <React.Fragment>
                          <Typography variant="body2" color="textSecondary">{SQLtoUTCDate(connector.results[0]?.timestamp, true)}</Typography>
                          <Typography variant="body2" color="textSecondary">{(connector.results[0]?.total_usage/1000).toFixed(3)}kWh</Typography>
                          <Typography variant="body2" color="textSecondary">{Boolean(connector.results[0]?.pass)?'PASS':'FAIL'}</Typography>
                        </React.Fragment>
                      }
                      {!connector.results[0] &&
                        <Typography variant="body2" color="textSecondary">Not tested</Typography>
                      }
                    </Box>
                    <IconButton size="small" color="primary">
                      <InfoIcon />
                    </IconButton>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>

          <Box style={{marginTop: 40}}>
            <ChargepointReports chargepoint={chargepoint} />
          </Box>

          <Box style={{marginTop: 40}}>
            <Typography variant="h6">Photos</Typography>
            <ChargepointPhotos chargepoint={chargepoint} />
          </Box>


          {configurationLog && 
            <Dialog open={true} fullWidth maxWidth="sm" onClose={() => setConfigurationLog(false)}>
              <DialogContent>
                <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography variant="h5" style={{display: 'flex', alignItems: 'center', gap: 15}}><InfoIcon/> Configuration Log</Typography>
                  <IconButton onClick={() => setConfigurationLog(false)}><CloseIcon /></IconButton>
                </Box>
                <Typography variant="body2">{configurationLog.id_user === null ? 'Owner' : 'Installer'}</Typography>
                <Typography variant="body2" color="textSecondary">{SQLtoUTCDate(configurationLog.timestamp, true)}</Typography>

                <Box style={{marginTop: 30, paddingBottom: 20}}>
                  <Typography variant="h6">Configuration</Typography>
                  <List dense style={{width: '100%'}}>
                    {JSON.parse(configurationLog.configuration).map((config, idx) => (
                      <ListItem key={idx} style={{gap: '15px'}}>
                        <Typography variant="body2">{config.key}: </Typography>
                        <Typography variant="body2">{config.value}</Typography>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </DialogContent>
            </Dialog>
          }


          {testLog && 
            <Dialog open={true} fullWidth maxWidth="sm" onClose={() => setTestLog(false)}>
              <DialogContent>
                <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography variant="h5" style={{display: 'flex', alignItems: 'center', gap: 15}}><InfoIcon/> Test Log</Typography>
                  <IconButton onClick={() => setTestLog(false)}><CloseIcon /></IconButton>
                </Box>
                
                <Typography variant="body2" color="primary">{chargepoint.cpid}</Typography>
                <Typography variant="body2">Connector {testLog.socket}</Typography>

                <Box style={{marginTop: 20, paddingBottom: 20}}>
                  <Typography variant="body2" color="textSecondary">History</Typography>
                  <List dense style={{width: '100%'}}>
                    {testLog.results.map((test, idx) => (
                      <ListItem key={idx} style={{gap: '30px', marginBottom: 6, borderTop: idx === 0 ? 'none' : '1px solid #e4e4e4'}}>
                        <Box>
                          <Typography variant="caption" color="textSecondary">Total pulled</Typography>
                          <Typography variant="body2">{(test.total_usage/1000).toFixed(3)}kWh</Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption" color="textSecondary">CSQ</Typography>
                          <Typography variant="body2">{test.csq}</Typography>
                        </Box>
                        <Box style={{flex: 1}}>
                          <Typography variant="caption" color="textSecondary">Error Code</Typography>
                          <Typography variant="body2" color="error">{test.error_code}</Typography>
                        </Box>
                        <Box>
                          <Typography variant="body1" align="right" color={Boolean(test.pass)?'primary':'error'}>{Boolean(test.pass)?'PASS':'FAIL'}</Typography>
                          <Typography variant="body2" align="right" color="textSecondary">{SQLtoUTCDate(test.timestamp, true)}</Typography>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </DialogContent>
            </Dialog>
          }



        </React.Fragment>
      }


    </React.Fragment>
  )

}

export default ChargepointMore