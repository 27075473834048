import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, {useContext, useEffect, useState} from 'react';
import InputField from '../components/inputField';
import ProgressButton from '../components/progressButton';
import globalContext from '../context/globalContext';
import API from '../global/api';
import { isValidForm, makeValidation } from '../global/formHelper';


const ProjectEdit = props => {

  const context = useContext(globalContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [statuses, setStatuses] = useState([]);
  const [project, setProject] = useState(props.project);

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'project_name',
      display_name: 'Project Name',
      required: true
    }
  ]))

  const handleInputChange = (e) => {
    let newProject = JSON.parse(JSON.stringify(project));
    newProject[e.target.name] = e.target.value;
    setProject(newProject);
  }

  const handleSubmit = async () => {

    try {

      const isValid = isValidForm(project, validation);
      setValidation(isValid.validation);
      if(isValid.isValid) {
        setError(null);
        setLoading(true);
        const updateProject = await API.req(`/project`, 'PUT', project);
        setLoading(false);
        if(updateProject.success){
          context.projectUpdate(updateProject.result);
          handleClose();
        } else{
          setError(updateProject.msg)
        }
      }
      
    } catch (error) {
      setLoading(false);
      setError(typeof error.msg !== 'undefined' ? error.msg : JSON.stringify(error))
    }

  }

  const handleClose = () => {
    setError(null);
    if(typeof props.onClose === 'function')
      props.onClose();
  }


  const handleLoadStatuses = async () => {

    try {

      setError(null);
      setLoading(true);

      const getStatuses = await API.req(`/project/statuses`, 'GET');
      setLoading(false);
      if(getStatuses.success){
        setStatuses(getStatuses.result);
      } else{
        setError(getStatuses.msg)
      }
      
    } catch (error) {
      setLoading(false);
      setError(typeof error.msg !== 'undefined' ? error.msg : JSON.stringify(error))
    }

  }


  useEffect(() => {
    handleLoadStatuses();
  }, [])


  return (
    <React.Fragment>

      <Dialog open={props.show} fullWidth maxWidth="xs" onClose={handleClose}>

        <DialogTitle>Update Project</DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}

          <InputField type="text" name="project_name" validation={validation.project_name} value={project.project_name} handleChange={handleInputChange} />

          <FormControl fullWidth style={{marginBottom: 10}} variant="filled">
            <InputLabel shrink htmlFor="status-placeholder">
              Status
            </InputLabel>
            <Select
              value={project.id_project_status}
              onChange={handleInputChange}
              inputProps={{
                name: 'id_project_status',
                id: 'status-placeholder',
              }}
            >
              {statuses.map((val, idx) => (
                <MenuItem key={idx} value={val.id_project_status}>{val.project_status}</MenuItem>
              ))}
            </Select>
          </FormControl>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ProgressButton variant="contained" onClick={handleSubmit} color="primary" waiting={loading}>UPDATE</ProgressButton>
        </DialogActions>

      </Dialog>

    </React.Fragment>
  )

}

export default ProjectEdit

