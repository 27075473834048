import React, {useEffect, useState} from 'react';
import {Typography, CircularProgress, Box, Divider} from '@material-ui/core';
import API from '../global/api';
import { SQLtoUTCDate } from '../global/dateTimeHelpers';
import ProjectNoteAdd from './projectNoteAdd';
import { Alert } from '@material-ui/lab';

const ProjectNotes = props => {

  const {id_project} = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [notes, setNotes] = useState(null);

  const loadNotes = async () => {

    try {

        setError(null);
        setLoading(true);

        const getNotes = await API.req(`/project/notes/${id_project}`, 'GET');

        if(!getNotes.success)
            throw getNotes.msg;

        setNotes(getNotes.result);
        setLoading(false);

        
    } catch (error) {
        setError('ERR_LOADING_CHARGEPOINTS');
    }

  }
  
  useEffect(() =>{
    loadNotes()
  }, [])


  const handleAddNote = note => {
    if(note !== null){
      let newNotes = JSON.parse(JSON.stringify(notes));
      newNotes.unshift(note);
      setNotes(newNotes);
    }
  }

  return (

    <React.Fragment>

      <Typography variant="h6">Notes</Typography>

      {loading && <Box><CircularProgress size={36} /></Box>}
      {(!loading && notes !== null && notes.length === 0) && 
        <Typography variant="h6" color="textSecondary">No notes to show</Typography>
      } 
      {error && <Alert severity="error">{error}</Alert> } 
      
      {(!loading && notes !== null && notes.length > 0) && 
        <Box className='pt'>
          <Box className='flex-col' style={{gap: '10px'}}>
            {notes.map((n, idx) => (
              <Box style={{paddingBottom: 10}} key={idx}>
                <Typography variant="body1">{n.entry}</Typography>
                <Typography variant="caption">{SQLtoUTCDate(n.created, true)} - {n.entry_name}</Typography>
                <Divider style={{marginTop: 10}} />
              </Box>
            ))}
          </Box>
        </Box>
      } 

      <ProjectNoteAdd id_project={id_project} onSuccess={handleAddNote} />

    </React.Fragment>

  );
}

export default ProjectNotes;
