import React, {useEffect, useState} from 'react';
import './styles.css';
import {makeStyles, useTheme, Typography, Button} from '@material-ui/core';
import { makeValidation, validateForm, isValidForm, resetValidation } from '../../global/formHelper';
import Alert from '@material-ui/lab/Alert'

import InputField from '../../components/inputField';
import ProgressButton from '../../components/progressButton';

import API from '../../global/api';

const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: 10
  },
  footer: {
    marginTop: 40,
    textAlign: 'center'
  },
}))


const ForgotPassword = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const [form, setForm] = useState({
    email: ''
  })

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'email',
      display_name: 'Email Address',
      required: true
    },
  ]));

  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = () => {
    setError(null);
    setSuccess(false);
    setWaiting(true);

    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid){

      API.req(`/user/password/forgot`, 'POST', form).then(
        res => {
          setWaiting(false);
          if(res.success === true){
            setSuccess(true);
            setValidation(resetValidation(validation))
            setForm({email: ''});
          } else
            setError(res.msg);
        },
        err => {
          setWaiting(false);
          setError(err)
        }
      )

    }
  }


  return (
    <div>

      {error && <div className="pb"><Alert severity='error'>{error}</Alert></div>}
      {success && <div className="pb"><Alert severity='success'>A link has been sent to your email which will allow you to reset your password.</Alert></div>}
      
      <div className={classes.form}>
        <Typography variant="caption">Enter the email address you use for your account and we will send your a link to reset your password.</Typography>
        <InputField name='email' size='large' validation={validation.email} value={form.email} handleChange={handleInputChange} />
      </div>

      <div className={classes.footer}>
        <ProgressButton variant="contained" size="large" color="primary" fullWidth waiting={waiting} onClick={handleSubmit} gutterBottom={true}>Reset Password</ProgressButton>
        <Button onClick={props.handleBack}>Back to sign in</Button>
      </div>

    </div>
  );
}

export default ForgotPassword;
