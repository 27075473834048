import Api from '../global/api';

export function login (fields) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'USER_LOGIN',
            status: 'loading',
            payload: null
        })

        Api.req('/user', 'POST', fields).then(
            async res => {

                
                Api.setLocal(process.env.REACT_APP_REFRESH_TOKEN_KEY || 'rt', res.result.rt);

                // Login successful
                dispatch({
                    type: 'USER_LOGIN',
                    status: 'in',
                    payload: res.result
                })
            },
            err => {
                // Login error
                dispatch({
                    type: 'USER_LOGIN',
                    status: 'error',
                    payload: typeof err.msg === 'string' ? err.msg : 'Sorry we were unable to log you in'
                })
            }
        )
    }
}


export function userValidate () {
    return async dispatch => {

        dispatch({
            type: 'USER_VERIFYING',
            payload: true
        })

        const refresh_token = await Api.getLocal(process.env.REACT_APP_REFRESH_TOKEN_KEY || 'rt');
        if(typeof refresh_token === 'undefined' || refresh_token === null)
            dispatch({
                type: 'USER_LOGOUT',
                payload: null
            })
        else {

            Api.req('/user', 'GET', null, {'x-refresh-token': refresh_token}, 5).then(
                async res => {

                    Api.setLocal(process.env.REACT_APP_REFRESH_TOKEN_KEY || 'rt', res.result.rt);

                    // Login successful
                    dispatch({
                        type: 'USER_LOGIN',
                        status: 'in',
                        payload: res.result
                    })
                },
                err => {

                    localStorage.removeItem(process.env.REACT_APP_REFRESH_TOKEN_KEY || 'rt');

                    // Login error
                    dispatch({
                        type: 'USER_LOGOUT',
                        payload: null
                    })

                }
            )
        }

    }
}

export function logout () {

    return async dispatch => {

        const refresh_token = await Api.getLocal(process.env.REACT_APP_REFRESH_TOKEN_KEY || 'rt');
        if(typeof refresh_token !== 'undefined' || refresh_token !== null)
            Api.req('/user', 'DELETE', null, {'x-refresh-token': refresh_token}).then(
                async res => {

                    //  Removed the current active profile, 
                    //  this will save bugs when another person logs in
                    await localStorage.removeItem(process.env.REACT_APP_REFRESH_TOKEN_KEY || 'rt');

                    // Logout successful
                    dispatch({
                        type: 'USER_LOGOUT',
                        payload: null
                    })

                },
                err => {
                    console.log(typeof err.msg === 'string' ? err.msg : 'Unable to log you out')
                }
            )

    }

}


export function register (fields) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'USER_LOGIN',
            status: 'loading',
            payload: null
        })

        Api.req('/user/register', 'POST', fields).then(
            async res => {

                Api.setLocal(process.env.REACT_APP_REFRESH_TOKEN_KEY || 'rt', res.result.rt);

                // Login successful
                dispatch({
                    type: 'USER_LOGIN',
                    status: 'in',
                    payload: res.result
                })
            },
            err => {
                console.log(err)
                // Login error
                dispatch({
                    type: 'USER_LOGIN',
                    status: 'error',
                    payload: typeof err === 'string' ? err : 'ERR_USER_REGISTER'
                })
            }
        )
    }
}