import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, {useState} from 'react';
import ProgressButton from '../components/progressButton';
import API from '../global/api';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';


const ChargepointReportRequest = props => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const {chargepoint} = props;



  const handleSubmit = async () => {

    try {
          setError(null);
          setLoading(true);
          setSuccess(false);

          const requestReport = await API.req(`/chargepoint/${chargepoint.id_chargepoint}/report`, 'POST');
          setLoading(false);

          if(requestReport.success){
            setSuccess(true);
            if(typeof props.onSuccess === 'function')
              props.onSuccess();
          } else{
            setError(requestReport.msg)
          }
      
    } catch (error) {
      setLoading(false);
      setError(typeof error === 'string' ? error : JSON.stringify(error))
    }

  }

  

  const handleClose = () => {
    setError(null);
    if(typeof props.onClose === 'function')
      props.onClose();
  }


  return (
    <React.Fragment>

      <Dialog open={props.show} fullWidth maxWidth="xs" onClose={handleClose}>

        <DialogTitle><Box sx={{display: 'flex', alignItems: 'center'}}><VerifiedUserIcon style={{marginRight: 5}} color="primary" />Chargepoint test report</Box></DialogTitle>
        
        
            <DialogContent>
              {error && <Alert severity="error">{error}</Alert>}
              {success && <Alert severity='success' style={{marginBottom: 10}}>Your chargepoint test report is being generated. You can browse away from this page without it being interupted. Your report will be downloadable once complete.</Alert>}
              <Typography variant="body1">Ensure all your testing is done before you generate a test report for "{chargepoint?.cpid}". </Typography>
            </DialogContent>

            <DialogActions>
              {!success && 
              <React.Fragment>
                <Button onClick={handleClose}>Cancel</Button>
                <ProgressButton variant="contained" onClick={handleSubmit} color="primary" waiting={loading}>CONFIRM</ProgressButton>
              </React.Fragment>
              }

              {success && <Button onClick={handleClose} variant="contained" color="primary">OK</Button>}
            </DialogActions>


      </Dialog>

    </React.Fragment>
  )

}

export default ChargepointReportRequest

