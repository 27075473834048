import { Box, CircularProgress, IconButton, List, Typography, ListItem, ListItemText } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, {useState, useEffect, useContext} from 'react'
import API from '../global/api';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useHistory } from 'react-router-dom';
import globalContext from '../context/globalContext';

const ProjectsRecent = props => {

  const history = useHistory();
  const context = useContext(globalContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [projects, setProjects] = useState([]);

  const loadRecentProjects = async () => {

    try {

      setLoading(true);
      const res = await API.req(`/projects/recent`, 'GET');
      if(res.success)
        setProjects(res.result);
      else
        throw res.msg;
      setLoading(false);
      
    } catch (error) {
      setLoading(false);
      setError('An error occured loading the most recent projects')
      console.log(error);
    }

  }

  useEffect(() => {
    loadRecentProjects()
  }, [])

  
  const handleClick = (p) => {
    context.projectAdd(p);
    history.push(`/project/${p.project_code}`)
  }

  return (
    <React.Fragment>
      <Box style={{marginTop: 20}}>
        <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10}}>
          <Typography variant="subtitle1" style={{paddingLeft: 15, fontWeight: 600}}>Recent</Typography>
          <IconButton size="small" color="primary" onClick={loadRecentProjects}><RefreshIcon /></IconButton>
        </Box>
        {loading && <CircularProgress color="primary" size={24} />}
        {error && <Alert severity="error">{error}</Alert>}
        {(projects.length === 0 && !loading) && <Typography variant="caption">You have no recent projects</Typography>}
        {(projects.length > 0 && !loading) &&
          <Box style={{maxHeight: 240, overflowY: 'auto'}}>
            <List disablePadding={true}>
              {projects.map((p, idx)=> (
                <ListItem key={idx} button onClick={() => handleClick(p)}>
                  <Box>
                    <Typography variant="subtitle2" color="primary">{`${p.project_code} - ${p.project_name}`}</Typography>
                    <Typography variant="caption">{`${new Date(p.created_on).toLocaleDateString('en-GB')} - ${p.project_status}`}</Typography>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        }
      </Box>
    </React.Fragment>
  )
}

export default ProjectsRecent