import React, { useContext, useEffect, useState } from 'react';
import InputField from '../components/inputField';
import { isValidForm, makeValidation, validateForm } from '../global/formHelper';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import API from '../global/api';
import globalContext from '../context/globalContext';
import ProgressButton from '../components/progressButton';

const ProjectAdd = props => {

  const context = useContext(globalContext);
  const [form, setForm] = useState({
    'project_name': '',
    'id_team': context.user.profile.user.preferences.default_id_team
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'project_name',
      display_name: 'Project Name',
      required: true
    },
    {
      name: 'id_team',
      display_name: 'Team',
      required: false
    }
  ]))

  const handleInputChange = e => {
    const newForm = JSON.parse(JSON.stringify(form))
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = async () => {

    try {

      const isValid = isValidForm(form, validation);
      setValidation(isValid.validation);
      if(isValid.isValid) {

          setLoading(true);
          const createProject = await API.req('/project', 'POST', {...form, project_type: 'COMM'});
          if(createProject.success){
            context.projectAdd(createProject.result);
            setLoading(false);
            if(typeof props.onSuccess === 'function')
              props.onSuccess();
          } else {
            throw createProject;
          }

      }
      
    } catch (error) {
      setLoading(false);
      setError(typeof error.msg !== 'undefined' ? error.msg : JSON.stringify(error))
    }

  }

  useEffect(() => {

    setValidation(validateForm(form, validation))

  }, [form])

  useEffect(() =>{
    context.teamsLoad();
  }, [])



  return (
    <Box style={{paddingTop: 30}}>
      {error && <Alert severity='error'>{error}</Alert>}
      <InputField type="text" name="project_name" validation={validation.project_name} value={form.project_name} handleChange={handleInputChange} />

      {context.teams.data.length > 0 && 
        <React.Fragment>
          <FormControl fullWidth style={{marginBottom: 10}} variant="filled">
            <InputLabel shrink htmlFor="team-placeholder">
              Team
            </InputLabel>
            <Select
              value={form.id_team}
              onChange={handleInputChange}
              inputProps={{
                name: 'id_team',
                id: 'team-placeholder',
              }}
            >
              <MenuItem value={0}>-- No Team --</MenuItem>
              {context.teams.data.map((val, idx) => (
                <MenuItem key={idx} value={val.id_team}>{val.team_name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </React.Fragment>
        }

      <Box style={{display: 'flex', justifyContent: 'flex-end'}}>
        <ProgressButton variant="contained" onClick={handleSubmit} color="primary" size="large" waiting={loading}>Create</ProgressButton>
      </Box>
    </Box>
  )

}

export default ProjectAdd