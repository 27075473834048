import React, { useState, useEffect, useContext } from 'react';
import { Box, List, ListItem, ListItemText, Typography, IconButton, CircularProgress, ListItemIcon } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';
import TeamEdit from './teamEdit';
import globalContext from '../context/globalContext';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import TeamDelete from './teamDelete';
import TeamMemberAdd from './teamMemberAdd';
import TeamMemberDelete from './teamMemberDelete';
import TeamMemberEdit from './TeamMemberEdit';


const TeamMemberItem = props => {

  const context = useContext(globalContext);
  const {member, idx, membersTotal} = props;

  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const handleDeleteSuccess = () => {
    setShowDelete(false);
    context.teamsLoad();
  }

  const handleEditSuccess = () => {
    setShowEdit(false);
    if(typeof props.membersLoad === 'function')
      props.membersLoad();
  }

  return (
      <React.Fragment>
        
        <ListItem style={{borderTop: idx === 0 ? 'none' : '1px solid #c4c4c4'}}>
          <ListItemText primary={member.name} secondary={member.role} />

          {member.id_user !== context.user.profile.user.id_user && 
            <Box>
              <IconButton color="primary" onClick={() => setShowEdit(true)}><EditIcon /></IconButton>
              {membersTotal > 1 && <IconButton color="primary" onClick={() => setShowDelete(true)}><CloseIcon /></IconButton>}
            </Box>
          }
        </ListItem>


        <TeamMemberDelete onSuccess={handleDeleteSuccess} member={member} show={showDelete} onClose={() => setShowDelete(false)} />
        <TeamMemberEdit onSuccess={handleEditSuccess} member={member} show={showEdit} onClose={() => setShowEdit(false)} />
      </React.Fragment>
  )
}




const TeamMore = props => {

  const context = useContext(globalContext);
  const [team, setTeam] = useState(props.team);

  const [showEdit, setShowEdit] = useState(false);
  const [showUnlink, setShowUnlink] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  //
  //  Loading members
  //

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null)
  const [members, setMembers] = useState([]);

  const loadMembers = async () => {

    try {

        setError(null);
        setLoading(true);

        const getMembers = await API.req(`/team/${team.id_team}/members`, 'GET');

        if(!getMembers.success)
            throw getMembers;

        setMembers(getMembers.result);
        setLoading(false);

        
    } catch (error) {
        setError('ERR_LOADING_MEMBERS');
    }

  }
  
  useEffect(() =>{
    loadMembers()
  }, [team])

  useEffect(() => {

    const findTeam = context.teams.data.find(t => t.id_team === props.team.id_team);
    if(typeof findTeam !== 'undefined' && JSON.stringify(findTeam) !== JSON.stringify(team))
      setTeam(findTeam);

    if(typeof findTeam === 'undefined' && typeof props.onClose === 'function')
      props.onClose();


  }, [context.teams.data])
  
  return (
    <React.Fragment>

      <Box className='flex-row-start'>        
        <Typography variant="subtitle1">{team.team_name}</Typography>
        <IconButton onClick={() => setShowEdit(true)} color="primary" size="small"><EditIcon fontSize='small' /></IconButton>
      </Box>

      
      <Box style={{paddingTop: 30}}>

        <Typography variant="h6">Members</Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {loading && <CircularProgress size={24} />}
        {!loading && 
          <List>
            {members.map((m, idx) => (
              <TeamMemberItem member={m} idx={idx} membersTotal={members.length} membersLoad={loadMembers} />
            ))}
          </List>
        }
      </Box>

      <TeamMemberAdd team={team} />

      <Box style={{flex: 1}}></Box>

      

      <Box style={{paddingTop: 30, paddingBottom: 30}}>
    
          <Typography variant="h6">Options</Typography>
          <List dense>
            <ListItem button onClick={() => setShowDelete(true)}>
              <ListItemIcon><DeleteIcon color="primary" /></ListItemIcon>
              <ListItemText primary="Delete this team" />
            </ListItem>
          </List>

      </Box>

      
      <TeamEdit team={team} show={showEdit} onClose={() => setShowEdit(false)} />
      <TeamDelete team={team} show={showDelete} onClose={() => setShowDelete(false)} />

    </React.Fragment>
  )

}

export default TeamMore