import React, { useState, useEffect } from 'react';
import {useTheme, makeStyles} from '@material-ui/core';
import InputField from '../components/inputField';
import { makeValidation, validateForm, isValidForm } from '../global/formHelper';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';
import ProgressButton from '../components/progressButton';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    marginTop: 20,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  },
}));


const ChangePassword = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const [form, setForm] = useState({
    new_password: '',
    confirm_password: ''
  })
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  //(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])(?=.{8,})

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'new_password',
      display_name: 'New Password',
      required: true,
      additional: [
        {
          type: 'password',
          error: 'Password must be at least 8 characters and include letters and numbers'
        }
      ]
    },
    {
      name: 'confirm_password',
      display_name: 'Confirm Password',
      required: true,
      additional: [
        {
          type: 'match',
          field: 'new_password',
          error: 'Your passwords do not match'
        }
      ]
    }
  ]));
  
  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = async () => {

    setError(null);
    setLoading(true);
    setSuccess(false);

    try {

      const valid = isValidForm(form, validation);
      setValidation(valid.validation);
      if(valid.isValid){

        await API.req('/user/password', 'PUT', {password: form.new_password}).then(
          res => {
            setSuccess(true);
            handleReset();
          },
          err => {
            setError(typeof err === 'string' ? err : JSON.stringify(err));
          }
        )

      }
      
    } catch (error) {

      setError('There was an error changing your password');
      
    } finally {
      setLoading(false);
    }
    
  }

  const handleReset = () => {
    setForm({
      new_password: '',
      confirm_password: ''
    })
  }

  return (
      <div className={classes.detailsWrapper}>
        {success && <Alert severity="success">Your password has been changed</Alert>}
        {!success && 
          <React.Fragment>
            {error && <Alert severity="error">{error}</Alert>}

            <div className={classes.form}>
              <InputField name='new_password' type='password' validation={validation.new_password} value={form.new_password} handleChange={handleInputChange} hoverLabel={true} />
              <InputField name='confirm_password' type='password' validation={validation.confirm_password} value={form.confirm_password} handleChange={handleInputChange} hoverLabel={true} />
            </div>

            <div style={{flex: 1}}></div>

            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
              <ProgressButton variant="contained" waiting={loading} onClick={handleSubmit}>&nbsp;&nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;&nbsp;</ProgressButton>
            </div>
          </React.Fragment>
          }
      </div>
  );
}

export default ChangePassword;
