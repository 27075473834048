import React, {useContext, useEffect, useState} from 'react';
import {Grid, makeStyles, useTheme, Box, Typography, Card, CardActionArea, IconButton} from '@material-ui/core';
import PageToolbar from '../components/pageToolbar';
import globalContext from '../context/globalContext';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useHistory } from 'react-router-dom';
import images from '../assets/imgs';
import SearchField from '../components/searchField';
import SearchResultProject from '../content/searchResultProject';
import API from '../global/api';
import ProjectsRecent from '../content/projectsRecent';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  cardIconed: {
    display: 'flex', 
    flexDirection: 'row', 
    gap: '20px'
  },
  cardIcon: {
    backgroundColor: theme.palette.primary.main, 
    color: theme.palette.primary.contrastText, 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    flexDirection: 'column', 
    minHeight: 100, 
    width: 100
  },
  cardIconedBody: {
    padding: 20, 
    flex: 1, 
    display: 'flex', 
    flexDirection: 'column', 
    gap: '6px'
  },
  cardIconedBtnGroup: {
    width: '100%', 
    display: 'flex',
    flexDirection: 'row', 
    justifyContent: 'flex-end'
  }
}))


const Home = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const history = useHistory();

  const [stats, setStats] = useState({
    comm_projects: 0,
    home_projects: 0
  })

  useEffect(() => {

    const comm_projects = context.project.data.filter(p => p.project_type === 'COMM' && [1,2].indexOf(p.id_project_status) > -1).length;
    const home_projects = context.project.data.filter(p => p.project_type === 'HOME' && [1,2].indexOf(p.id_project_status) > -1).length;

    setStats({
      ...stats,
      comm_projects,
      home_projects
    })

  }, [context.project.data])

  const handleSearch = async search => {
    
    try {

      const res = await API.req(`/projects/search?s=${search}`, 'GET');
      return res.result
      
    } catch (error) {
      console.log(error);
      return []
    }

  }


  return (
    <div className={classes.container}>

      <PageToolbar title={`Hi ${context.user.profile.user.first_name}`}></PageToolbar>

      <Grid container spacing={2}>

        <Grid item xs={12} sm={6} md={4} xl={3}>
          <Box style={{padding: 20}}>
            <SearchField placeholder="Search projects" onSearch={handleSearch} resultComponent={r => <SearchResultProject result={r} />}/>
            <ProjectsRecent />
          </Box>
        </Grid>

        
        <Grid item xs={12} sm={6} md={4} xl={3}>

          <Card elevation={1}>
            <CardActionArea onClick={() => history.push('/projects/commercial')}>
              <Box className={classes.cardIconed}>
                <Box className={classes.cardIconedBody}>

                  <img src={images.icon_commercial} alt="Commercial Projects" style={{height: 180, width: 'auto'}} />
                  <Typography variant="h5" align="center">Commercial Projects</Typography>
                  <Typography variant="h1" align="center">{stats.comm_projects}</Typography>

                  <Box className={classes.cardIconedBtnGroup}>
                    <IconButton variant="contained" color="primary" size="small"><ArrowForwardIcon /></IconButton>
                  </Box>
                </Box>
              </Box>
            </CardActionArea>
          </Card>

        </Grid>

        
        <Grid item xs={12} sm={6} md={4} xl={3}>

          <Card elevation={1}>
            <CardActionArea onClick={() => history.push('/projects/home')}>
              <Box className={classes.cardIconed}>
                <Box className={classes.cardIconedBody}>
                  <img src={images.icon_home} alt="Home Projects" style={{height: 180, width: 'auto'}} />
                  <Typography variant="h5" align="center">Home Projects</Typography>
                  <Typography variant="h1" align="center">{stats.home_projects}</Typography>
                  <Box className={classes.cardIconedBtnGroup}>
                    <IconButton variant="contained" color="primary" size="small"><ArrowForwardIcon /></IconButton>
                  </Box>
                </Box>
              </Box>
            </CardActionArea>
          </Card>

        </Grid>

      </Grid>

    </div>
  );
}

export default Home;
