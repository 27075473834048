import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';


import Main from './context/main';

import Home from './routes/home';
import Profile from './routes/profile';
import NotFound from './routes/notFound';

import Signin from './content/signin/signin';
import ResetPassword from './content/signin/resetPassword';
import ProjectsCommercial from './routes/projects/commercial';
import ProjectsHome from './routes/projects/home';
import Project from './routes/project';
import Teams from './routes/teams';
import InviteAccept from './routes/inviteAccept';


const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    overflow: 'auto'
  }
}))


const App = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
          
    <Router>
      <div className={classes.root}>
        {props.user.status !== 'in' && 
        
          <Switch>
              <Route path={"/invite/:token"} exact component={Signin} ></Route>
              <Route path={"/reset_password/:token"} exact component={ResetPassword} ></Route>
              <Route component={Signin} />
          </Switch>
        
        }

        {props.user.status === 'in' &&
        
          <SnackbarProvider
            maxSnack={3} 
            anchorOrigin={{vertical: 'top', horizontal: 'right',}}
          >
            <Main>

              <Switch>
                  <Route path={"/"} exact component={Home} ></Route>
                  <Route path={"/profile"} exact component={Profile} ></Route>
                  <Route path={"/project/:project_code"} exact component={Project} ></Route>
                  <Route path={"/projects/commercial"} exact component={ProjectsCommercial} ></Route>
                  <Route path={"/projects/home"} exact component={ProjectsHome} ></Route>
                  <Route path={"/teams"} exact component={Teams} ></Route>
                  <Route path={"/invite/:token"} exact component={InviteAccept} ></Route>
                  <Route component={NotFound} />
              </Switch>

            </Main>
          </SnackbarProvider>

        }
      </div>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
