import { Box } from "@material-ui/core"
import images from "../assets/imgs"


const ChargepointIcon = props => {

    const getIcon = () => {
        if(typeof props.type === 'undefined' || props.type === null)
            return images.cp_default;

        switch(props.type.toUpperCase()){
            case 'AUTOCHARGE':
                return images.cp_autocharge;
            case 'BASICCHARGE':
                return images.cp_basiccharge;
            case 'QUANTUM':
                return images.cp_quantum;
            case 'SECURICHARGE':
                return images.cp_securicharge;
            case 'WALLPOD':
                return images.cp_wallpod;
            case 'ZURA':
                return images.cp_zura;
            default: 
                return images.cp_default;
        }
    }
    const icon  = getIcon();

    return (
        <Box style={{height: 80, width: 'auto', ...props.style || {}}}>

            <img src={icon} style={{height: '100%', width: 'auto'}} alt="chargepoint" />

        </Box>
    )
}

export default ChargepointIcon