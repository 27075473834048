import React, { useContext, useState } from 'react';
import {withRouter} from 'react-router-dom';
import {useTheme, Toolbar, IconButton, makeStyles, Link, Popover, Typography, Divider, Hidden} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Images from '../assets/imgs/index';
import AccountAvatar from './accountAvatar';
import GlobalContext from '../context/globalContext';
import PersonIcon from '@material-ui/icons/Person';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  fill: {
    flex: 1,
  },
  logo: {
    width: 220,
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menu: {
    width: 300,
  },
  name: {
    margin: theme.spacing(2)
  },
  meta: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  organisations: {
    maxHeight: 200,
    overflowY: 'auto'
  }
}));


const MainToolbar = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(GlobalContext)

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const onChangeRoute = route => {
    props.history.push(route);
  } 


  const handleAvatarClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    if(typeof context.logout === 'function')
      context.logout();
  }

  return (
    <Toolbar>
      <Hidden mdUp implementation="css">
        <IconButton edge="start" color="inherit" aria-label="menu" className={classes.menuButton} onClick={props.toggleMenu}>
          <MenuIcon />
        </IconButton>
      </Hidden>
      <Link onClick={() => {onChangeRoute('/')}} style={{lineHeight: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}><img src={Images.logo_inline} className={classes.logo} alt="Rolec EV Connect" /></Link>
      <div className={classes.fill}></div>
      <AccountAvatar user={context.user} userLogout={context.userLogout} handleClick={handleAvatarClick}/>


      <Popover 
        id='avatar-menu'
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.menu}>
          <div className={classes.name}>
            <div className="flex-row align-center">
              <div className="flex">
                <Typography>{context.user.profile.user.first_name} {context.user.profile.user.last_name}</Typography>
                <Typography variant="caption" color="textSecondary" component="div">{context.user.profile.user.username}</Typography>
                <div className='flex-col mt'>
                  <Link href='/profile' style={{cursor: 'pointer', alignItems: 'center', display: 'flex', gap: '10px'}}><PersonIcon color="primary" />My Profile</Link>
                  <Link onClick={handleLogout} style={{cursor: 'pointer', alignItems: 'center', display: 'flex', gap: '10px'}}><PowerSettingsNewIcon color="primary" />Sign out</Link>
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div className={classes.meta}>
            <div className="flex-row align-center">
              <Typography variant="caption" color="textSecondary">v{context.version}</Typography>
            </div>
          </div>
        </div>
      </Popover>
    </Toolbar>
  );
}

export default withRouter(MainToolbar);
