import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, {useContext, useState} from 'react';
import InputField from '../components/inputField';
import ProgressButton from '../components/progressButton';
import globalContext from '../context/globalContext';
import API from '../global/api';
import { isValidForm, makeValidation } from '../global/formHelper';


const TeamEdit = props => {

  const context = useContext(globalContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [team, setTeam] = useState(props.team);

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'team_name',
      display_name: 'Team Name',
      required: true
    }
  ]))

  const handleInputChange = (e) => {
    let newTeam = JSON.parse(JSON.stringify(team));
    newTeam[e.target.name] = e.target.value;
    setTeam(newTeam);
  }

  const handleSubmit = async () => {

    try {

      const isValid = isValidForm(team, validation);
      setValidation(isValid.validation);
      if(isValid.isValid) {
        setError(null);
        setLoading(true);
        const updateTeam = await API.req(`/team`, 'PUT', team);
        setLoading(false);
        if(updateTeam.success){
          context.teamUpdate(updateTeam.result);
          handleClose();
        } else{
          setError(updateTeam.msg)
        }
      }
      
    } catch (error) {
      setLoading(false);
      setError(typeof error.msg !== 'undefined' ? error.msg : JSON.stringify(error))
    }

  }

  const handleClose = () => {
    setError(null);
    if(typeof props.onClose === 'function')
      props.onClose();
  }


  return (
    <React.Fragment>

      <Dialog open={props.show} fullWidth maxWidth="xs" onClose={handleClose}>

        <DialogTitle>Update Team</DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}

          <InputField type="text" name="team_name" validation={validation.team_name} value={team.team_name} handleChange={handleInputChange} />


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ProgressButton variant="contained" onClick={handleSubmit} color="primary" waiting={loading}>UPDATE</ProgressButton>
        </DialogActions>

      </Dialog>

    </React.Fragment>
  )

}

export default TeamEdit

