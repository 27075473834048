import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, useTheme, Typography, Box, IconButton, Paper, Grid} from '@material-ui/core';
import globalContext from '../context/globalContext';
import InfoIcon from '@material-ui/icons/Info';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory, useParams } from 'react-router-dom';
import ProjectMore from '../content/projectMore';
import SideSlidePanel from '../components/sideSlidePanel';
import ProjectChargePoints from '../content/projectChargePoints';
import ProjectSites from '../content/projectSites';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  busyBox: {
    height: 300, 
    width: '100%', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center'
  }
}))


const Project = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);
  const history = useHistory();
  const {project_code} = useParams();

  const [project, setProject] = useState(null);
  const [showMore, setShowMore] = useState(false);
  


  useEffect(() =>{
    context.projectsLoad();
  }, [])

  useEffect(() => {

    if(context.project.data !== null){
      const filterProjects = context.project.data.filter(p => p.project_code === project_code);
      if(filterProjects.length > 0)
        setProject(filterProjects[0]);
    }

  }, [context.project.data])

  const goBack = () => {

    if(history.action === 'PUSH')
      history.goBack()
    else if(project.project_type === 'COMM')
      history.replace('/projects/commercial')
    else 
      history.replace('/projects/home')
  }


  return (

    <div className={classes.container}>

      {(context.project.status !== 'LOADING' && project !== null) &&
        <React.Fragment>
          <Paper style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
            <Box style={{padding: '30px 20px 30px 20px', flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 20}}>
                  <ChevronLeftIcon style={{fontSize: 42, color: theme.palette.primary.main, cursor: 'pointer'}} onClick={goBack}/>
                  <Box>
                    <Typography variant="h5">{project.project_name}</Typography>
                    <Typography variant="subtitle1" color="textSecondary">Created: {new Date(project.created_on).toLocaleDateString('en-GB')}</Typography>
                    <Typography variant="subtitle2">Status: {project.project_status}</Typography>
                  </Box>
                </Box>
                <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 20}}>
                  <Typography variant="h3">{project.project_code}</Typography>
                  <IconButton color="primary" onClick={() => setShowMore(true)}><InfoIcon /></IconButton>
                </Box>
            </Box>
          </Paper>

          <Grid container spacing={2}>

            <Grid item xs={12} lg={6}>
              <ProjectChargePoints project_code={project_code} />
            </Grid>

            {project.project_type === 'COMM' &&
              <Grid item xs={12} lg={6}>
                <ProjectSites project_code={project_code} id_project={project.id_project} />
              </Grid>
            }

          </Grid>
        </React.Fragment>
      }


      <SideSlidePanel show={showMore} handleClose={() => setShowMore(false)} title={`Project: ${project !== null && project.project_name}`} side="RIGHT"><ProjectMore project={project} onClose={() => setShowMore(false)} /></SideSlidePanel>
      
      
    </div>
  );
}

export default Project;
