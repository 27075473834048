import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, Box} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, {useContext, useState, useEffect} from 'react';
import InputField from '../components/inputField';
import ProgressButton from '../components/progressButton';
import globalContext from '../context/globalContext';
import API from '../global/api';
import { isValidForm, makeValidation, resetValidation, validateForm } from '../global/formHelper';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ProjectChargePointsSelect from './projectChargePointsSelect';

const ProjectTicket = props => {

  const context = useContext(globalContext);

  const project = props.project;

  const [form, setForm] = useState({
    'id_project': project.id_project,
    'description': '',
    'phone': context.user.profile.user.phone,
    'email': context.user.profile.user.email,
    'subject' : `${project?.project_name} - ${project?.project_code}`
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'description',
      display_name: 'Describe the issue',
      required: true
    },
    {
      name: 'phone',
      display_name: 'Your phone number',
      required: true
    },
    {
      name: 'email',
      display_name: 'Your email address',
      required: true
    }
  ]))

  const handleInputChange = e => {
    const newForm = JSON.parse(JSON.stringify(form))
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleChargePointsChange = cp => {
    let newSubject = `${project?.project_name} - ${project?.project_code}: ${cp.join(', ')}`;
    console.log(newSubject);
    const newForm = JSON.parse(JSON.stringify(form))
    newForm.subject = newSubject;
    setForm(newForm);
  }

  const handleSubmit = async () => {

    try {

      const isValid = isValidForm(form, validation);
      setValidation(isValid.validation);
      if(isValid.isValid) {

          setLoading(true);

          const createProjectTicket = await API.req(`/project/ticket/${project.id_project}`, 'POST', form);
          if(createProjectTicket.success){
            setLoading(false);
            setForm({
              'description': '',
              'phone': context.user.profile.user.phone,
              'email': context.user.profile.user.email,
              'subject' : `${project?.project_name} - ${project?.project_code}`
            })
            setValidation(resetValidation(validation));
            if(typeof props.onSuccess === 'function')
              props.onSuccess(createProjectTicket.result);
          } else {
            setLoading(false);
            setError(createProjectTicket.msg)
          }

      }
      
    } catch (error) {
      setLoading(false);
      setError(typeof error.msg !== 'undefined' ? error.msg : JSON.stringify(error))
    }

  }

  const handleClose = () => {
    setError(null);
    if(typeof props.onClose === 'function')
      props.onClose();
  }

  useEffect(() => {

    setValidation(validateForm(form, validation))

  }, [form])
  

  return (
    <React.Fragment>

      <Dialog open={props.show} fullWidth maxWidth="sm" onClose={handleClose}>

        <DialogTitle><Typography variant="h5" style={{display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center'}}><ContactSupportIcon color="primary" style={{fontSize: 32}} />Raise support request</Typography></DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <Typography variant="body2">Notify the Rolec technical support team of an technical issue relating one or more charge points on this project.</Typography>
          <Box style={{marginTop: 10}}>
            <Typography variant="subtitle1">Project: <span style={{fontWeight: 600}}>{project?.project_name} - {project?.project_code}</span></Typography>
            <Typography variant="subtitle1">Reported by: <span style={{fontWeight: 600}}>{context.user.profile.user.first_name} {context.user.profile.user.last_name}</span></Typography>
            <Box style={{marginTop: 10, display: 'flex', flexDirection: 'column', gap: '6px'}}>        
              <ProjectChargePointsSelect project_code={project.project_code} handleChange={handleChargePointsChange} />
              <InputField type="text" hoverLabel={true} multiline rows={7} name="description" validation={validation.description} value={form.description} handleChange={handleInputChange} />
              <InputField type="text" hoverLabel={true} name="email" validation={validation.email} value={form.email} handleChange={handleInputChange} />
              <InputField type="text" hoverLabel={true} name="phone" validation={validation.phone} value={form.phone} handleChange={handleInputChange} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ProgressButton variant="contained" onClick={handleSubmit} color="primary" waiting={loading}>SEND</ProgressButton>
        </DialogActions>


      </Dialog>

    </React.Fragment>
  )

}

export default ProjectTicket

