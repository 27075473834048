import React from 'react';
import {useTheme, makeStyles, Typography, Box} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 20px',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '10px'
  }
}));


const AccountAvatar = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const {user} = props;

  const handleClick = e => {
    if(typeof props.handleClick === 'function')
      props.handleClick(e);
  }

  return (
    <div className={classes.wrapper} onClick={handleClick}>
        <Box style={{display: 'flex', flexDirection: 'column'}}>
          <Typography component="span" style={{lineHeight: '1.3em', fontWeight: 400}} align="right" variant="body1">{user.profile.user.first_name} {user.profile.user.last_name}</Typography>
          <Typography variant="subtitle2" component="span" style={{lineHeight: '1.3em'}} align="right">{user.profile.user.company_name}</Typography>
        </Box>
        <MenuIcon color="primary" style={{fontSize: 36, cursor: 'pointer'}} />
    </div>
  );
}

export default AccountAvatar;
