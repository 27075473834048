import React, {useState} from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Hidden, makeStyles, useTheme } from '@material-ui/core';
import Images from '../assets/imgs/index';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme, drawerWidth) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      height: '100%',
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'relative',
    height: '100%',
  },
  icon: {
    height: 76,
    width: 'auto'
  }
}));


const MainNavigation = props => {

  const theme = useTheme();
  const history = useHistory();

  
  const [drawerWidth, setDrawerWidth] = useState(80);

  const classes = useStyles(theme, drawerWidth);

  const handleChangeRoute = route => {
    history.push(route);
  } 

  const routes = [
    {
      label: 'Home',
      route: '/',
      icon: <img src={Images.icon_dashboard} alt="Home" className={classes.icon} />
    },
    {
      label: 'Commercial Projects',
      route: '/projects/commercial',
      icon: <img src={Images.icon_commercial} alt="Commercial Projects" className={classes.icon} />
    },
    {
      label: 'Home Installations',
      route: '/projects/home',
      icon: <img src={Images.icon_home} alt="Home Installations" className={classes.icon} />
    },
    {
      label: 'Teams',
      route: '/teams',
      icon: <img src={Images.icon_installer} alt="Home Installations" className={classes.icon} />
    },
  ]

  const toggleShortDrawer = () => {
    setDrawerWidth(drawerWidth === 80 ? 240 : 80);
  }

  return (
    <React.Fragment>

      
      <Hidden smDown implementation="css">
        <div className={classes.drawer}>

          <Drawer open variant="permanent" classes={{
              root: classes.drawerPaper,
              paper: classes.drawerPaper,
          }}>
            <div style={{padding: 20}}>
              <img src={Images.logo_icon} style={{width: 40}} alt="Rolec EV Connect" onClick={toggleShortDrawer} />
            </div>
            <List>
              {routes.map((r, idx) => (
                <ListItem button key={idx} onClick={() => handleChangeRoute(r.route)} disableGutters={true}>
                  {r.icon && <ListItemIcon>{React.cloneElement(r.icon, {color: "primary"})}</ListItemIcon>}
                </ListItem>
              ))}
            </List>
          </Drawer>
          
        </div>
      </Hidden>

      <Hidden mdUp implementation="css">
        <Drawer anchor={'left'} open={props.open} onClose={props.toggleDrawer}>
          <div onClick={props.toggleDrawer} onKeyDown={props.toggleDrawer} style={{width: 300}}>
            <div style={{padding: 20}}>
              <img src={Images.logo_icon} style={{width: 40}} alt="Rolec EV Connect" />
            </div>
            <List>
              {routes.map((r, idx) => (
                <ListItem button key={idx} onClick={() => handleChangeRoute(r.route)}>
                  {r.icon && <ListItemIcon>{React.cloneElement(r.icon, {color: "primary"})}</ListItemIcon>}
                  <ListItemText primary={r.label} />
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
      </Hidden>


    </React.Fragment>
  )
}

export default MainNavigation;