import store from './store';
const {request} = require('axios'); 

class API {
    static ENDPOINT = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api/v1.0' : '/api/v1.0';;

    /**
     * 
     * @param {*} uri The path to be added to the end of your endpoint e.g. /login
     * @param {*} method GET | POST | PUT | DELETE
     * @param {*} body The params to be passed either in the body of a POST or PUT or as the params of a GET
     * @returns Promise with the response from the server
     */
    static req = (uri = null, method = null, body = {}, headers = {}) => {

        //
        //  Build the headers
        //
        let reqHeaders = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...headers
        };

        const state = store.getState();
        if(state.user.status === 'in' && state.user.profile.token !== null)
            reqHeaders['x-access-token'] = state.user.profile.token;

        //
        //  Add in the query string or body
        //
        const params = {};
        if(method === 'POST' || method === 'PUT')
            params.data = body;
        if(method === 'GET')
            params.params = body;

        /*
        * AXIOS
        */
        return new Promise((resolve, reject) => {
            request({
                ...params,
                url: this.ENDPOINT + uri,
                method: method,
                headers: reqHeaders,
                timeout: 120000,
                withCredentials: true
            })
            .then(res => {
                if(res.status === 200 && res.data.success === true)
                    resolve(res.data);
                else{
                    if(typeof res.data.code !== 'undefined')
                        reject(res.data);
                    else
                        reject(res.data.msg)
                }
            })
            .catch(err => {
                reject(err);
            })
        });
    }


    static fileUpload = (uri = null, body = null) => {


        //
        //  Build the headers
        //
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        const state = store.getState();
        if(state.user.status === 'in' && state.user.profile.token !== null)
            headers['x-access-token'] = state.user.profile.token;

        
        let formData = new FormData();

        Object.keys(body).map(name => {
            formData.append(name, body[name]);
        })

        return new Promise((resolve, reject) => {
            request({
                url: this.ENDPOINT + uri,
                headers: headers,
                method: 'POST',
                data: formData,
                timeout: 10000,
                withCredentials: true
            })
            .then(res => {
                if(res.status === 200 && res.data.success === true)
                    resolve(res.data);
                else
                    reject(typeof res.data.msg !== 'undefined' ? res.data.msg : res);
            })
            .catch(err => {
                reject(typeof err.msg !== 'undefined' ? err.msg : err);
            })
        });

    }


    static fileDownload = (uri = null, filename = new Date().getTime(), signal = null) => {

        let headers = {};

        const state = store.getState();
        if(state.user.status === 'in' && state.user.profile.token !== null)
            headers['x-access-token'] = state.user.profile.token;


        return new Promise((resolve, reject) => {

            request({
                url: this.ENDPOINT + uri,
                headers: headers,
                method: 'GET',
                timeout: 30000,
                withCredentials: true,
                signal: signal,
                responseType: 'blob',
            })
            .then(res => {

                if(res.status === 200){

                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);

                    resolve({
                        success: true,
                        result: filename
                    });

                } else {
                    reject('DOWNLOAD FAILED');
                }

            })
            .catch(err => {
                reject(typeof err.msg !== 'undefined' ? err.msg : err);
            })

        });

    }
    

    static addToLocalArray = async (storage_key, val) => {

        const local_array = localStorage.getItem(storage_key);
        let new_array = local_array !== null ? JSON.parse(local_array) : [];
        new_array.push(val);
        localStorage.setItem(storage_key, JSON.stringify(new_array));
        return {
            success: true,
            result: val
        };

    }

    static getLocal = async (storage_key) => {

        const local_array = localStorage.getItem(storage_key);
        return local_array !== null ? JSON.parse(local_array) : null;

    }

    static setLocal = async (storage_key, val) => {
        
        localStorage.setItem(storage_key, JSON.stringify(val));

    }

}
export default API